var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-overflow-y-scroll tw-h-full md:tw-p-20 tw-bg-gray-100"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-20"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-x-4"},[_c('b-switch',{model:{value:(_vm.showDropdownRuns),callback:function ($$v) {_vm.showDropdownRuns=$$v},expression:"showDropdownRuns"}},[_vm._v("Show Dropdown Runs")]),_c('b-switch',{model:{value:(_vm.showStartupRuns),callback:function ($$v) {_vm.showStartupRuns=$$v},expression:"showStartupRuns"}},[_vm._v("Show Startup Runs")])],1),_c('div',{staticClass:"tw-p-8 tw-h-128 tw-bg-white tw-rounded tw-border-gray-300 tw-border-2"},[(_vm.runsWeeklySuccessFailure.chartData.datasets.length > 0)?_c('Bar',{key:_vm.barKey,staticClass:"tw-w-full",attrs:{"options":_vm.runsWeeklySuccessFailure.chartOptions,"data":_vm.runsWeeklySuccessFailure.chartData}}):_vm._e()],1),_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-x-16 tw-items-stretch tw-flex-wrap tw-gap-y-20"},[_c('div',{staticClass:"tw-border-2 tw-border-gray-300 tw-rounded tw-bg-white tw-p-10 tw-flex-1"},[_c('div',{staticClass:"tw-text-center tw-font-bold tw-text-xl tw-pb-6"},[_vm._v(" Most Used Commands "),_c('div',{staticClass:"tw-float-right tw-cursor-pointer",on:{"click":_vm.downloadTrendingCommands}},[_c('b-icon',{staticClass:"tw-text-gray-400 hover:tw-text-gray-500",attrs:{"icon":"download","size":"is-small"}})],1)]),_c('b-table',{attrs:{"data":_vm.legosTrending,"paginated":"","per-page":"10"}},[_c('b-table-column',{attrs:{"label":"Directory","cell-class":"tw-truncate"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.directoryName))]}}])}),_c('b-table-column',{attrs:{"label":"Name","cell-class":"tw-truncate"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                name: 'kitBuilderRoute',
                query: { id: props.row.commandId.toString() },
                params: { orgId: _vm.$route.params.orgId },
              },"target":"_blank"}},[_vm._v(_vm._s(props.row.name))])]}}])}),_c('b-table-column',{attrs:{"label":"Lifetime","header-class":"tw-truncate"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.allTimeWebFilters))]}}])}),_c('b-table-column',{attrs:{"label":"Past Month","header-class":"tw-truncate"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.pastMonthWebFilters))]}}])})],1)],1),_c('div',{staticClass:"tw-border-2 tw-border-gray-300 tw-rounded tw-bg-white tw-p-10 tw-flex-1"},[_c('div',{staticClass:"tw-text-center tw-font-bold tw-text-xl tw-pb-6"},[_vm._v(" Most Active Runners ")]),_c('b-table',{attrs:{"data":_vm.runnersTrending,"paginated":"","per-page":"10"}},[_c('b-table-column',{attrs:{"label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{on:{"click":function($event){_vm.isHistorySidePanelActive = true;
                _vm.legoRunTableFilters = [("user_id:" + (props.row.userId))];
                _vm.historySidePanelHeader = (props.row.name) + "'s runs";}}},[_vm._v(_vm._s(props.row.name))])]}}])}),_c('b-table-column',{attrs:{"label":"Lifetime"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.totalUsage))]}}])}),_c('b-table-column',{attrs:{"label":"Past Month"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.recentUsage))]}}])})],1)],1)]),_c('div',{staticClass:"tw-p-8 tw-h-128 tw-bg-white tw-rounded tw-border-gray-300 tw-border-2"},[(_vm.syncAdditionsWeeklyByUser.chartData.datasets.length > 0)?_c('Bar',{staticClass:"tw-w-full",attrs:{"options":_vm.syncAdditionsWeeklyByUser.chartOptions,"data":_vm.syncAdditionsWeeklyByUser.chartData}}):_vm._e()],1)]),(_vm.isHistorySidePanelActive)?_c('HistorySidePanel',{attrs:{"legoRunTableFilters":_vm.legoRunTableFilters,"headerText":_vm.historySidePanelHeader},on:{"close":function($event){_vm.isHistorySidePanelActive = false;
      _vm.legoRunTableFilters = [];
      _vm.historySidePanelHeader = '';}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }