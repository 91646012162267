var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SidePanel2',{attrs:{"isLoading":_vm.syncingInProgress},on:{"close":function($event){return _vm.$emit('closeSyncSidePanel')}}},[_c('SidePanelNavigator',{ref:"navigator",on:{"navigation-changed":_vm.handleNavigationChange},scopedSlots:_vm._u([{key:"diff$scrolls",fn:function(){return [_c('HeaderBody',{attrs:{"headerText":("Diff " + (_vm.credDisplayName(_vm.credToSync))),"padMobile":"","padDesktop":"","tightDesktop":""}},[(
            _vm.diffResults !== null &&
            _vm.diffResults.added.length == 0 &&
            _vm.diffResults.deleted.length == 0 &&
            _vm.diffResults.modified.length == 0 &&
            _vm.diffResults.undeleted.length == 0 &&
            _vm.diffResults.error === null
          )?_c('div',[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-4 tw-text-center tw-items-center tw-max-w-xl tw-mx-auto tw-pt-12"},[_c('div',{staticClass:"tw-font-bold"},[_vm._v("No changes detected in database.")]),_c('MarkdownDisplay',{staticClass:"tw-text-gray-600",attrs:{"markdown":_vm.fyiMsg}}),_c('div',[_c('b-button',{attrs:{"icon-left":"redo-alt","type":"is-primary"},on:{"click":_vm.refreshDiff}},[_vm._v("Refresh")])],1)],1)]):(_vm.diffResults !== null)?_c('div',{staticClass:"tw-mb-20"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-y-2 tw-justify-between tw-bg-white tw-sticky tw-top-0 tw-z-10"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-x-2 tw-py-2"},[_c('b-button',{attrs:{"icon-left":"redo-alt","type":"is-primary is-outlined"},on:{"click":_vm.refreshDiff}}),_c('b-button',{attrs:{"type":"is-primary","disabled":_vm.checkedRowsAdded.length === 0 &&
                  _vm.checkedRowsDeleted.length === 0 &&
                  _vm.checkedRowsModified.length === 0 &&
                  _vm.checkedRowsUndeleted.length === 0},on:{"click":_vm.commitChanges}},[_c('span',{staticClass:"tw-hidden md:tw-inline"},[_vm._v("Commit Changes")]),_c('span',{staticClass:"md:tw-hidden"},[_vm._v("Commit")])]),_c('b-button',{attrs:{"type":"is-light is-danger","disabled":_vm.checkedRowsAdded.length === 0 &&
                  _vm.checkedRowsDeleted.length === 0 &&
                  _vm.checkedRowsModified.length === 0 &&
                  _vm.checkedRowsUndeleted.length === 0},on:{"click":_vm.addToFlankIgnore}},[_c('span',{staticClass:"md:tw-hidden"},[_vm._v(".flankignore")]),_c('span',{staticClass:"tw-hidden md:tw-inline"},[_vm._v("Add to .flankignore")])])],1)]),(_vm.diffResults.error === null)?_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-8"},[(_vm.diffResults !== null && _vm.diffResults.undeleted.length > 0)?_c('div',[_c('div',{staticClass:"tw-font-mono tw-font-bold tw-text-lg sm:tw-text-xl tw-mt-8 tw-mb-6 tw-ml-0"},[_vm._v(" undelete in flank ")]),_c('div',{staticClass:"tw-w-full tw-border tw-px-2 tw-py-4 tw-rounded-md tw-bg-gray-50"},[_c('b-table',{attrs:{"data":_vm.diffResults !== null ? _vm.diffResults.undeleted : [],"checkedRows":_vm.checkedRowsUndeleted,"checkable":true,"mobile-cards":false,"default-sort":['name', 'asc']},on:{"update:checkedRows":function($event){_vm.checkedRowsUndeleted=$event},"update:checked-rows":function($event){_vm.checkedRowsUndeleted=$event}}},[[_c('b-table-column',{attrs:{"field":"diffType","width":"25"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
                          _vm.syncTypeToColor(props.row.diffType),
                          'tw-font-mono tw-text-sm tw-font-bold' ]},[_vm._v(_vm._s(_vm.formatSyncType(props.row.diffType)))])]}}],null,false,2008583603)}),_c('b-table-column',{attrs:{"field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tw-font-mono tw-text-sm"},[_vm._v(_vm._s(props.row.cloudName ? props.row.cloudName : ""))])]}}],null,false,1931250290)})]],2)],1)]):_vm._e(),(_vm.diffResults !== null && _vm.diffResults.added.length > 0)?_c('div',[_c('div',{staticClass:"tw-font-mono tw-font-bold tw-text-lg sm:tw-text-xl tw-mt-8 tw-mb-6 tw-ml-0"},[_vm._v(" add to flank ")]),_c('div',{staticClass:"tw-w-full tw-border tw-px-2 tw-py-4 tw-rounded-md tw-bg-gray-50"},[_c('b-table',{attrs:{"data":_vm.diffResults !== null ? _vm.diffResults.added : [],"checkedRows":_vm.checkedRowsAdded,"checkable":true,"mobile-cards":false,"default-sort":['name', 'asc']},on:{"update:checkedRows":function($event){_vm.checkedRowsAdded=$event},"update:checked-rows":function($event){_vm.checkedRowsAdded=$event}}},[[_c('b-table-column',{attrs:{"field":"diffType","width":"25"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
                          _vm.syncTypeToColor(props.row.diffType),
                          'tw-font-mono tw-text-sm tw-font-bold' ]},[_vm._v(_vm._s(_vm.formatSyncType(props.row.diffType)))])]}}],null,false,2008583603)}),_c('b-table-column',{attrs:{"field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tw-font-mono tw-text-sm"},[_vm._v(_vm._s(props.row.cloudName ? props.row.cloudName : ""))])]}}],null,false,1931250290)})]],2)],1)]):_vm._e(),(_vm.diffResults !== null && _vm.diffResults.deleted.length > 0)?_c('div',[_c('div',{staticClass:"tw-font-mono tw-font-bold tw-text-lg sm:tw-text-xl tw-mt-8 tw-mb-6 tw-ml-0"},[_vm._v(" delete from flank ")]),_c('div',{staticClass:"tw-w-full tw-border tw-px-2 tw-py-4 tw-rounded-md tw-bg-gray-50"},[_c('b-table',{attrs:{"data":_vm.diffResults !== null ? _vm.diffResults.deleted : [],"checkedRows":_vm.checkedRowsDeleted,"checkable":true,"mobile-cards":false,"default-sort":['name', 'asc']},on:{"update:checkedRows":function($event){_vm.checkedRowsDeleted=$event},"update:checked-rows":function($event){_vm.checkedRowsDeleted=$event}}},[[_c('b-table-column',{attrs:{"field":"diffType","width":"25"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
                          _vm.syncTypeToColor(props.row.diffType),
                          'tw-font-mono tw-text-sm tw-font-bold' ]},[_vm._v(_vm._s(_vm.formatSyncType(props.row.diffType)))])]}}],null,false,2008583603)}),_c('b-table-column',{attrs:{"field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tw-font-mono tw-text-sm"},[_vm._v(_vm._s(props.row.cloudName ? props.row.cloudName : ""))])]}}],null,false,1931250290)})]],2)],1)]):_vm._e(),(_vm.diffResults !== null && _vm.diffResults.modified.length > 0)?_c('div',[_c('div',{staticClass:"tw-font-mono tw-font-bold tw-text-lg sm:tw-text-xl tw-mt-8 tw-mb-6 tw-ml-0"},[_vm._v(" update inputs in flank ")]),_c('div',{staticClass:"tw-w-full tw-border tw-px-2 tw-py-4 tw-rounded-md tw-bg-gray-50"},[_c('b-table',{attrs:{"data":_vm.diffResults !== null ? _vm.diffResults.modified : [],"checkedRows":_vm.checkedRowsModified,"checkable":true,"mobile-cards":false,"default-sort":['name', 'asc']},on:{"update:checkedRows":function($event){_vm.checkedRowsModified=$event},"update:checked-rows":function($event){_vm.checkedRowsModified=$event}}},[[_c('b-table-column',{attrs:{"field":"diffType","width":"25"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
                          _vm.syncTypeToColor(props.row.diffType),
                          'tw-font-mono tw-text-sm tw-font-bold' ]},[_vm._v(_vm._s(_vm.formatSyncType(props.row.diffType)))])]}}],null,false,2008583603)}),_c('b-table-column',{attrs:{"field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tw-font-mono tw-text-sm"},[_vm._v(_vm._s(props.row.cloudName ? props.row.cloudName : ""))])]}}],null,false,1931250290)})]],2)],1)]):_vm._e()]):(_vm.diffResults.error !== null)?_c('div',[_c('ErrorBox',{attrs:{"error":_vm.diffResults.error}})],1):_vm._e()]):_vm._e()])]},proxy:true},{key:"view-committed$scrolls",fn:function(){return [_c('HeaderBody',{attrs:{"headerText":'Committing Changes',"padMobile":"","padDesktop":"","tightDesktop":""}},[_c('b-table',{attrs:{"mobile-cards":false,"data":_vm.checkedRowsFlattenedByInstance,"row-class":function (row, index) {
              if (row.diffType === 'deleted') {
                return 'tw-cursor-default';
              }
              return 'tw-cursor-pointer';
            }}},[_c('b-table-column',{attrs:{"width":"25"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
                _vm.syncTypeToColor(props.row.diffType),
                'tw-font-mono tw-text-sm tw-font-bold' ]},[_vm._v(" "+_vm._s(_vm.formatSyncType(props.row.diffType))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"dirName","label":"Name","cell-class":"md:tw-hidden tw-relative height41","header-class":"md:tw-hidden"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-dropdown',{staticClass:"tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"tw-h-full tw-px-3 tw-text-gray-900 hover:tw-text-gray-900 tw-flex tw-flex-row tw-gap-x-2 tw-items-center"},[_c('div',{staticClass:"tw-flex-none tw-flex tw-flex-row tw-items-center"},[_c('b-icon',{staticClass:"tw-text-gray-300",attrs:{"icon":"file-code","size":"is-small","icon-pack":"far"}})],1),_c('div',{staticClass:"tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden"},[_vm._v(" "+_vm._s(props.row.name)+" ")])])]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"has-link":""}},[_c('router-link',{attrs:{"to":{
                    name: 'kitBuilderRoute',
                    query: { id: props.row.kitId.toString() },
                    params: { orgId: _vm.$store.state.activeOrg.orgId },
                  },"target":"_blank"}},[_vm._v("Go To Command")])],1),_c('b-dropdown-item',{on:{"click":function () {
                    _vm.sidePanelz.shareCmd.kitId = props.row.kitId;
                    _vm.sidePanelz.shareCmd.name = props.row.name;
                    _vm.sidePanelz.shareCmd.kitVisibility =
                      props.row.kitVisibility;
                    _vm.sidePanelz.shareCmd.isOpen = true;
                    _vm.navigate('push', 'share-committed');
                  }}},[_vm._v("Share")]),_c('b-dropdown-item',{on:{"click":function () {
                    _vm.sidePanelz.configureCmd.isOpen = true;
                    _vm.sidePanelz.configureCmd.kitId = props.row.kitId;
                    _vm.navigate('push', 'configure-committed');
                  }}},[_vm._v("Configure")])],1)]}}])}),_c('b-table-column',{attrs:{"label":"Name","cell-class":"tw-hidden md:tw-table-cell tw-relative height41","header-class":"tw-hidden md:tw-table-cell"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c(props.row.diffType === 'deleted' ? 'div' : 'router-link',{tag:"component",staticClass:"tw-absolute tw-top-0 tw-left-0 tw-px-3 tw-h-full tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-flex tw-flex-row tw-gap-x-2 tw-items-center",attrs:{"to":props.row.diffType === 'deleted'
                  ? null
                  : {
                      name: 'kitBuilderRoute',
                      query: { id: props.row.kitId.toString() },
                      params: { orgId: _vm.$store.state.activeOrg.orgId },
                    },"target":"_blank"}},[_c('div',{staticClass:"tw-flex-none tw-flex tw-flex-row tw-items-center"},[_c('b-icon',{staticClass:"tw-text-gray-300",attrs:{"icon":"file-code","size":"is-small","icon-pack":"far"}})],1),_c('div',{staticClass:"tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden"},[_vm._v(" "+_vm._s(props.row.name)+" ")]),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-x-3 tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-justify-end"},[_c('b-button',{staticClass:"show-on-hover is-small",attrs:{"type":"is-primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () {
                      _vm.sidePanelz.configureCmd.isOpen = true;
                      _vm.sidePanelz.configureCmd.kitId = props.row.kitId;
                      _vm.navigate('push', 'configure-committed');
                    }).apply(null, arguments)}}},[_vm._v("Configure")]),_c('div',[_c('b-button',{staticClass:"is-small show-on-hover",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () {
                        _vm.sidePanelz.shareCmd.kitId = props.row.kitId;
                        _vm.sidePanelz.shareCmd.name = props.row.name;
                        _vm.sidePanelz.shareCmd.kitVisibility =
                          props.row.kitVisibility;
                        _vm.sidePanelz.shareCmd.isOpen = true;
                        _vm.navigate('push', 'share-committed');
                      }).apply(null, arguments)}}},[_vm._v("Share")])],1)],1)])]}}])})],1)],1)]},proxy:true},{key:"share-committed",fn:function(){return [(_vm.sidePanelz.shareCmd.isOpen)?_c('UltimateShareFooterWrapper',{attrs:{"kitId":_vm.sidePanelz.shareCmd.kitId,"kitName":_vm.sidePanelz.shareCmd.name,"kitVisibility":_vm.sidePanelz.shareCmd.kitVisibility,"shareType":'kit'},on:{"close":function () {
            _vm.sidePanelz.shareCmd.isOpen = false;
            _vm.sidePanelz.shareCmd.kitId = null;
            _vm.sidePanelz.shareCmd.name = null;
            _vm.sidePanelz.shareCmd.kitVisibility = 'org';
            _vm.navigate('pop');
          },"emitIsLoading":function($event){_vm.syncingInProgress = $event},"emitUpdateKitVisibility":function () {
            // no op
          }}}):_vm._e()]},proxy:true},{key:"configure-committed$nested",fn:function(){return [(_vm.sidePanelz.configureCmd.isOpen)?_c('ConfigureCmdTree',{attrs:{"kitId":_vm.sidePanelz.configureCmd.kitId,"directories":_vm.directories,"propInitKitDoll":null,"propInitDropdownResults":null,"propInitKitRun":null,"viewingPublicly":false,"propKitInstanceId":null,"startingTab":0,"isKili":false,"isNested":true,"indexBump":2},on:{"emitIsLoading":function($event){_vm.syncingInProgress = $event},"close":function () {
            _vm.sidePanelz.configureCmd.isOpen = false;
            _vm.sidePanelz.configureCmd.kitId = null;
            _vm.navigate('pop');
          },"emitKitDollUpdate":function ($event) {
            _vm.$emit('emitKitDollUpdate', $event);
            if ('kitDoll' in $event) {
              var cmdInstance = _vm.checkedRowsFlattenedByInstance.find(
                function (row) { return row.kitId === $event.kitDoll.kitId; }
              );
              if (cmdInstance) {
                cmdInstance.name = $event.kitDoll.name;
              }
            }
          },"emitDropdownResultsUpdate":function () {
            // no op
          },"emitCreatedDirectory":function($event){return _vm.$emit('emitCreatedDirectory', $event)}}}):_vm._e()]},proxy:true}]),model:{value:(_vm.navigationState),callback:function ($$v) {_vm.navigationState=$$v},expression:"navigationState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }