// https://patorjk.com/software/taag/#p=display&f=Colossal&t=Math

import _ from "lodash";
import _join from "lodash-joins";
import { format as formatTimeAgo } from "timeago.js";
import {
  format,
  utcToZonedTime,
  zonedTimeToUtc,
  getTimezoneOffset,
} from "date-fns-tz";
import { marked } from "marked";
import {
  tzList,
  tzListCommon,
  abbreviationToIANATimeZone,
  timezoneAbbrMap,
} from "./timezones";
import parser from "cron-parser";
import cronstrue from "cronstrue";
export function identity(e: any): any {
  return e;
}
function _stringKeyToFunction(
  key: string | ((arg0: any) => any)
): (arg0: any) => any {
  if (typeof key === "function") {
    return key;
  }
  return (e) => oget(e, key);
}
// _stringKeyToFunction("someKey")({someKey: "foo"}); //=
// // "foo"

// _stringKeyToFunction((e) => e.someKey)({someKey: "foo"}); //=
// // "foo"
/*

8888888b.          888    888               
888   Y88b         888    888               
888    888         888    888               
888   d88P 8888b.  888888 88888b.  .d8888b  
8888888P"     "88b 888    888 "88b 88K      
888       .d888888 888    888  888 "Y8888b. 
888       888  888 Y88b.  888  888      X88 
888       "Y888888  "Y888 888  888  88888P' 
                                            
                                            
                                            
*/

export function extractIndex(pathPart: string): number {
  if (!isIndex(pathPart)) {
    return -1;
  }
  return parseInt(lfirst(pathPart.match(/\d+/) ?? []) ?? "-1");
}

// extractIndex("[1234]"); //=
// extractIndex("a[1234]"); //=
// extractIndex("[2823]"); //=
// extractIndex(pathFirst("[0].id")); //=
// extractIndex(pathFirst("id")); //=

export function isIndex(pathPart: string) {
  return pathPart.match(/^\[\d+\]$/) !== null;
}

// isIndex("[0]"); //=
// isIndex("[abd]"); //=
// isIndex("123"); //=
// isIndex("[123"); //=
// isIndex("aa[123]"); //=
// isIndex("aa[123]aaa"); //=
// isIndex("[0000]"); //=
// isIndex("[1234]"); //= true

export function containsIndex(path: string) {
  return path.match(/\[\d+\]/) !== null;
}

// containsIndex("[0]"); //= true
// containsIndex("a.b.c"); //= false
// containsIndex("a.b[1].c"); //= true
// containsIndex("a.b[1].c[0]"); //= true
// containsIndex("a.b[].c[]"); //= false
// containsIndex("a.b.[1].c"); //= false

export function strInt(path: string | number): number {
  return Number(path);
}
export function pathJoin(a: string | number, b: string | number): string {
  if (pyfalsey(a)) {
    return b.toString();
  }
  if (pyfalsey(b)) {
    return a.toString();
  }
  if (isIndex(b.toString())) {
    return `${a}${b}`;
  }

  return strim(a.toString(), ".") + "." + strim(b.toString(), ".");
}

// pathJoin("a", "b") //=
// // a.b
// pathJoin("a", "") //=
// // a
// pathJoin("a", "[0]"); //=

export function pathsJoin(...paths: (string | number)[]): string {
  return paths
    .filter((val) => !pyfalsey(val))
    .map((s) => s.toString())
    .map((s) => strim(s, "."))
    .reduce((acc, val) => {
      return pathJoin(acc, val);
    }, "");
}

// pathsJoin("a", "b", "c"); //=
// pathsJoin("", "b", "c"); //=
// pathsJoin("", "b", ""); //=
// pathsJoin(["a", "b", "c"]); //= // WRONG
// pathsJoin(...["a", "b", "c"]); //= . // RIGHT
// pathsJoin("a", "[0]"); //=
// pathsJoin([]); //=
// pathsJoin("a"); //=

export function pathSplit(path: string | number): string[] {
  return path
    .toString()
    .split(".")
    .map((e) => {
      const match = reverpolate(e, /{key}\[{index}\]/);
      if (match.index && match.key) {
        return [match.key, `[${match.index}]`];
      } else {
        return e;
      }
    })
    .flat()
    .filter((e) => (typeof e === "string" ? e.length > 0 : true)) as string[];
}

// pathSplit("a.b"); //=
// pathSplit(""); //=
// pathSplit("123"); //=
// pathSplit(1); //=
// pathSplit("a[0].b.c"); //=
// pathSplit("[0].b.c"); //=
// reverpolate("a[0]", /{key}\[{index}\]/); //=

export function pathFirst(path: string) {
  return lfirst(pathSplit(path), "");
}

// pathFirst('a.b.c') //=
// // 'a'
// pathFirst('a[0].b.c') //=
// // a
// pathFirst(""); //=
// ""

export function pathRest(path: string) {
  return lrest(pathSplit(path)).join(".");
}

export function pathLength(path: string) {
  return pathSplit(path).length;
}

export function pathChopLast(path: string) {
  return pathSplit(path).slice(0, -1).join(".");
}

// pathChopLast("a.b.c"); //=
// pathChopLast("a"); //=

export function pathLast(path: string) {
  return llast(pathSplit(path), "");
}

// pathLast("") //=
// // ''

export function pathPeekSecond(path: string) {
  return pathFirst(pathRest(path));
}

// pathPeekSecond(""); //=
/*

 .d88888b.  888       d8b                   888             
d88P" "Y88b 888       Y8P                   888             
888     888 888                             888             
888     888 88888b.  8888  .d88b.   .d8888b 888888 .d8888b  
888     888 888 "88b "888 d8P  Y8b d88P"    888    88K      
888     888 888  888  888 88888888 888      888    "Y8888b. 
Y88b. .d88P 888 d88P  888 Y8b.     Y88b.    Y88b.       X88 
 "Y88888P"  88888P"   888  "Y8888   "Y8888P  "Y888  88888P' 
                      888                                   
                     d88P                                   
                   888P"                                    
*/
export function ocopy<T>(o: T): T {
  return _.cloneDeep(o);
}

// const o = { a: 1, b: { nested: "hi" } };
// const c = ocopy(o);
// ocopy(45); //=
// o.b.nested = "something else";
// c.b.nested === "hi"; //=

export function okeyfilter(
  o: Record<string, unknown>,
  f: (arg0: string) => boolean
): Record<string, unknown> {
  return Object.fromEntries(Object.entries(o).filter(([key]) => f(key)));
}
export function ovalueFilter(
  o: Record<string, unknown>,
  f: (arg0: unknown) => boolean
): Record<string, unknown> {
  return Object.fromEntries(Object.entries(o).filter(([, value]) => f(value)));
}
// ovalueFilter({ a: 1, b: 2, c: 3 }, (e) => true); //=
export function oget(
  o: any,
  path: string | number | null | undefined,
  _default: any = null
): any {
  if (path === null || path === undefined) {
    return _default;
  }
  return _.get(o, path, _default);
}

// oget([], "length") //=
// oget(null, "length") //=
// oget(null, "a"); //=
// oget(undefined, "a"); //=
// oget({ 1: 2 }, 1); //=
// // 2
// oget({ 1: 1 }, null); //=
// oget({ 1: 1 }, undefined); //=

export function ogetGen<T, D>(
  o: { [key: string]: T } | { [key: number]: T },
  path: string | number | null | undefined,
  _default: D = (null as unknown) as D
): T | D {
  if (path === null || path === undefined) {
    return _default;
  }
  return _.get(o, path, _default);
}
export function oset(o: any, path: string, value: any): any {
  const c = ocopy(o);
  return _.set(c, path, value);
}
export function oapplyAt(
  o: any,
  path: string,
  f: (arg0: unknown) => unknown
): any {
  return oset(o, path, f(oget(o, path)));
}
export function ohas(o: any, path: string): any {
  return _.has(o, path);
}

export function okeys(o: any): string[] {
  return Object.keys(o);
}
export function okeysGen<T extends Record<string | number, unknown>>(
  o: T
): (keyof T)[] {
  return Object.keys(o);
}
export function ovalues(o: any): any[] {
  return Object.values(o);
}
export function ovaluesGen<T>(o: Record<string, T>): T[] {
  return Object.values(o);
}
export function oempty(o: any): boolean {
  return Object.keys(o).length == 0;
}
export function oitems(o: Record<string, unknown>): [string, unknown][] {
  return Object.entries(o);
}
export function ovaluemap(
  o: Record<string, unknown> | any[] | any,
  f: (arg0: unknown) => unknown,
  { recurse = false } = {}
): Record<string, unknown> | any[] | any {
  if (isObject(o)) {
    const items = oitems(o);
    const mapped = [] as any[];
    for (const [k, v] of items) {
      if (isObject(v) && recurse) {
        mapped.push([
          k,
          ovaluemap(v as Record<string, unknown>, f, { recurse }),
        ]);
      } else if (isArray(v) && recurse) {
        mapped.push([k, ovaluemap(v as any[], f, { recurse })]);
      } else {
        mapped.push([k, f(v)]);
      }
    }
    return Object.fromEntries(mapped);
  } else if (isArray(o) && recurse) {
    return (o as any[]).map((e) => ovaluemap(e, f, { recurse }));
  } else if (recurse) {
    return f(o);
  } else {
    throw new Error(
      "ovaluemap called on non-object with recurseOnNonObjects = false"
    );
  }
}

// // should be same
// ovaluemap({ a: 1, b: 2 }, (e) => e + 1); //=
// ovaluemap({ a: 1, b: 2 }, (e) => e + 1, { recurse: true }); //=

// // this should only work properly with recurse
// ovaluemap({ a: 1, b: 2, c: { e: 1 } }, (e) => e + 1); //=
// ovaluemap({ a: 1, b: 2, c: { e: 1 } }, (e) => e + 1, { recurse: true }); //=

// // ditto, array
// ovaluemap({ a: 1, b: 2, c: { e: 1, g: [1, 2, 3] } }, (e) => e + 1); //=
// ovaluemap({ a: 1, b: 2, c: { e: 1, g: [1, 2, 3] } }, (e) => e + 1, {
//   recurse: true,
// }); //=

// // ditto, array of objs
// ovaluemap({ c: { e: 1, g: [{ a: 1 }, { a: 1 }] } }, (e) => e + 1); //=
// ovaluemap({ c: { e: 1, g: [{ a: 1 }, { a: 1 }] } }, (e) => e + 1, {
//   recurse: true,
// }).c.g; //=

// // ditto, array at root
// ovaluemap([{ a: 1 }, { a: 1 }], (e) => e + 1, { recurse: true }); //=
// // ovaluemap([{ a: 1 }, { a: 1 }], (e) => e + 1); //=

// // ditto, nested array at root
// ovaluemap([[1], [[1]]], (e) => e + 1, { recurse: true }); //=
// // ovaluemap([[1], [[1]]], (e) => e + 1); //=

// // test with function that is designed to NOT be recursive and to operate on nested arrays
// ovaluemap(
//   {
//     "123": [
//       ["name", "key", "value"],
//       ["name", "key", "value"],
//     ],
//   },
//   (l) => l.map(([i, k, v]) => [k, v])
// ); //=
// // ovaluemap(
// //   {
// //     "123": [
// //       ["name", "key", "value"],
// //       ["name", "key", "value"],
// //     ],
// //   },
// //   (l) => l.map(([i, k, v]) => [k, v]),
// //   { recurse: true }
// // ); //=

// // primitive at root
// ovaluemap(5, (e) => e + 1, { recurse: true }); //=
// ovaluemap(5, (e) => e + 1); //=

export function okeymap(
  o: Record<string, unknown>,
  f: (arg0: string) => string
): Record<string, unknown> {
  const mapped = oitems(o).map(([k, v]) => [f(k), v]);
  return Object.fromEntries(mapped);
}

// okeymap({ a: 1, b: 2 }, (k) => k + "hi"); //=
// // { ahi: 1, bhi: 2 }

export function omv(
  o: Record<string, unknown>,
  src: string,
  dest: string
): Record<string, unknown> {
  return odrop(oset(o, dest, oget(o, src)), [src]);
}

// omv({a: 1}, 'a', 'b') //=
// // {b: 1}
// omv({ a: { b: 1 }, c: 5 }, "c", "a.j"); //=
// // {a: { b: 1, j: 5}}

export function omerge<T extends object, U extends object[]>(
  o: T,
  ...others: U
): T & UnionToIntersection<U[number]> {
  const copy = ocopy(o);
  const copies = others.map((e) => ocopy(e));
  return _.merge(copy, ...copies) as T & UnionToIntersection<U[number]>;
}

// Utility type to intersect all the types in the tuple `U`
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

// omerge({ commands: [1, 2, 3] }, { commands: [7] }); //=
// omerge({ commands: [1, 2, 3] }, { commands: [7] }); //=
// ({ ...{ commands: [1, 2, 3] }, ...{ commands: [7] } }); //=
// omerge({ a: [1, 2] }, { a: [3, 4] }, { a: [5, 6] }); //=
// omerge(
//   { key: "hi", type: "mssql", constraints: { length: 2 }, paramParent: "1234" },
//   { key: "hi", type: "python", constraints: { length: 1 } }
// ); //=
// // { constraints: { length: 1 },
// //   key: 'hi',
// //   paramParent: '1234',
// //   type: 'python' }

export function omergeRecursive(
  o1: Record<string, unknown>,
  o2: Record<string, unknown>
): Record<string, unknown> {
  // takes LAST one it sees
  const returnObj = {} as any;
  for (const key of lunique(okeys(o1).concat(okeys(o2)))) {
    // if only exists in one or the other, take that one
    if (key in o1 && !(key in o2)) {
      returnObj[key] = o1[key];
    } else if (key in o2 && !(key in o1)) {
      returnObj[key] = o2[key];
    } else {
      // otherwise exists in both
      // if both are objects, recurse
      if (isObject(o1[key]) && isObject(o2[key])) {
        returnObj[key] = omergeRecursive(
          o1[key] as Record<string, unknown>,
          o2[key] as Record<string, unknown>
        );
      }
      // if one is an object, take that one over an array or primitive
      else if (isObject(o1[key])) {
        returnObj[key] = o1[key];
      } else if (isObject(o2[key])) {
        returnObj[key] = o2[key];
      }
      // if either is an array, take that over a primitive, but take o2's array over o1's
      else if (isArray(o2[key])) {
        returnObj[key] = o2[key];
      } else if (isArray(o1[key])) {
        returnObj[key] = o1[key];
      } else {
        // otherwise, take o2 over o1
        returnObj[key] = o2[key];
      }
    }
  }
  return returnObj;
}

// omerge({ a: [1, 2] }, { a: [3, 4] }, { a: [5, 6] }); //=
// omergeRecursive({ a: 1 }, { b: 2 }); //=
// // { a: 1, b: 2 }
// omergeRecursive({ b: 1 }, { b: 2 }); //=
// // { b: 2 }
// omergeRecursive({ b: { c: 1 } }, { b: 2 }); //=
// // { b: { c: 1 } }
// omergeRecursive({ b: { c: 1 } }, { b: { c: 5 } }); //=
// // { b: { c: 5 } }
// omergeRecursive({ b: { c: [1, 2, 3] } }, { b: { c: [4, 5, 6] } }); //=
// // { b: { c: [4, 5, 6] } }

export function okeep(
  o: Record<string, unknown>,
  paths: Array<string>
): Record<string, unknown> {
  return _.pick(o, paths);
}

// okeep({ a: 1, b: 2, c: 3 }, ["a", "c", "d"]); //=

export function odrop<T extends Record<string, any>, K extends string>(
  o: T,
  paths: K[]
): Omit<T, K> {
  return _.omit(o, paths);
}

// odrop({ a: 1, b: 2, c: 3 }, ["a"]); //=
// nested doesn't work
// odrop({ a: { b: 1, c: 2 } }, ["a.b"]); //=
export function oKeysToCamel(o: any) {
  if (isObject(o)) {
    const n: any = {};

    Object.keys(o).forEach((k) => {
      n[snakeToCamel(k)] = oKeysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return oKeysToCamel(i);
    });
  }

  return o;
}

export const oKeysToSnake = function (o: any) {
  if (isObject(o)) {
    const n: any = {};

    Object.keys(o).forEach((k) => {
      n[camelToSnake(k)] = oKeysToSnake(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return oKeysToSnake(i);
    });
  }

  return o;
};

function oremoveEmptyPaths(o: any) {
  const returnObj = {} as any;
  for (const key of okeys(o)) {
    if (isObject(o[key])) {
      const child = oremoveEmptyPaths(o[key]);
      if (!oempty(child)) {
        returnObj[key] = child;
      }
    } else if (!pyfalsey(o[key])) {
      returnObj[key] = o[key];
    }
  }
  return returnObj;
}
// oremoveEmptyPaths({ a: 1, b: { c: 2, d: {} } }); //= // {a: 1, b: {c: 2}}
// oremoveEmptyPaths({ a: { b: { c: { d: {} } } }, l: [] }); //= // {}

export function odiffKeys<K extends string | number>(
  old: Record<K, unknown>,
  updated: Record<K, unknown>
): [K[], K[]] {
  const oldKeys = okeys(old) as K[];
  const updatedKeys = okeys(updated) as K[];
  return [
    oldKeys.filter((k) => !updatedKeys.includes(k)),
    updatedKeys.filter((k) => !oldKeys.includes(k)),
  ];
}

// odiffKeys({ a: 1, b: 2, c: 3 }, { a: 1, b: 2, d: 4 }); //=

export function odiffOldNew(
  old: any,
  updated: any,
  maxDepth = 10000,
  depth = 0
) {
  const oldKeys = okeys(old);
  const updatedKeys = okeys(updated);
  if (!equals(new Set(oldKeys), new Set(updatedKeys))) {
    console.log(oldKeys);
    console.log(updatedKeys);
    throw new Error("old and updated objects must have the same keys");
  }

  const r = {} as any;
  const l = {} as any;
  for (const key of oldKeys) {
    if (
      (isObject(old[key]) && !isObject(updated[key])) ||
      (isObject(updated[key]) && !isObject(old[key])) ||
      (isArray(old[key]) && !isArray(updated[key])) ||
      (isArray(updated[key]) && !isArray(old[key]))
    ) {
      l[key] = old[key];
      r[key] = updated[key];
    } else if (isObject(old[key])) {
      if (depth < maxDepth) {
        const [cl, cr] = odiffOldNew(
          old[key],
          updated[key],
          maxDepth,
          depth + 1
        );
        l[key] = cl;
        r[key] = cr;
      } else {
        if (!equals(old[key], updated[key])) {
          l[key] = old[key];
          r[key] = updated[key];
        }
      }
    } else if (isArray(old[key])) {
      const [ll, rl] = ldiffOrderInsensitive(old[key], updated[key]);
      if (ll.length > 0 || rl.length > 0) {
        l[key] = ll;
        r[key] = rl;
      }
    } else if (old[key] !== updated[key]) {
      l[key] = old[key];
      r[key] = updated[key];
    }
  }
  // MP: removing the below for now because it breaks non-null -> null diff
  // r = oremoveEmptyPaths(r);
  // l = oremoveEmptyPaths(l);
  return [l, r];
}

// odiffOldNew(
//   { schema: [{ a: 1 }, { a: 2 }], same: 1 },
//   { schema: [{ a: 1 }], same: 1 }
// ); //=

export function odiffOldNewDiffLengths(old: any, updated: any) {
  const oldKeys = okeys(old);
  const updatedKeys = okeys(updated);
  let r = {} as any;
  let l = {} as any;
  let inBoth = oldKeys;
  if (!equals(new Set(oldKeys), new Set(updatedKeys))) {
    const onlyL = oldKeys.filter((k) => !updatedKeys.includes(k));
    const onlyR = updatedKeys.filter((k) => !oldKeys.includes(k));
    inBoth = oldKeys.filter((k) => updatedKeys.includes(k));
    for (const key of onlyL) {
      l[key] = old[key];
    }
    for (const key of onlyR) {
      r[key] = updated[key];
    }
  }
  for (const key of inBoth) {
    if (
      (isObject(old[key]) && !isObject(updated[key])) ||
      (isObject(updated[key]) && !isObject(old[key])) ||
      (isArray(old[key]) && !isArray(updated[key])) ||
      (isArray(updated[key]) && !isArray(old[key]))
    ) {
      l[key] = old[key];
      r[key] = updated[key];
    } else if (isObject(old[key])) {
      const [cl, cr] = odiffOldNew(old[key], updated[key]);
      l[key] = cl;
      r[key] = cr;
    } else if (isArray(old[key])) {
      const [ll, rl] = lexclusive(old[key], updated[key]);
      if (ll.length > 0 || rl.length > 0) {
        l[key] = ll;
        r[key] = rl;
      }
    } else if (old[key] !== updated[key]) {
      l[key] = old[key];
      r[key] = updated[key];
    }
  }

  // MP: removing the below for now because it breaks non-null -> null diff
  // r = oremoveEmptyPaths(r);
  // l = oremoveEmptyPaths(l);
  return [l, r];
}

// const o = { a: 1, b: 2, c: { d: { e: "Acc" } }, l: [1, 2, 3] };
// const u = { a: 1, b: 2, c: { d: { e: "Eng" } }, l: [1, 2, 3, "on-site"] };

// oflatten(odiffOldNew(o, u)[1]); //=

// const elCamello = {
//   meGusto: "los",
//   camellos: { ayAyAy: "camello", lista: [{ elCamello: 1 }, { elCamello: 2 }] },
// };
// const culebra = oKeysToSnake(elCamello); //=
// // { camellos: { ay_ay_ay: 'camello', lista: [ { el_camello: 1 }, { el_camello: 2 } ] }, me_gusto: 'los' }
// const laCamella = oKeysToCamel(culebra); //=
// equals(laCamella, elCamello); //=
// oKeysToSnake([elCamello, laCamella]); //=

// function that flattens nested objects like {awsCreds: {roleArn: "blahblah"}} into flat objects like {roleArn: "blahblah"}

export function oflatten(obj: any, onlyUseLeafNames = false, prefix = "") {
  if (pyfalsey(obj)) {
    return obj;
  } else if (!isObject(obj) && !isArray(obj)) {
    return obj;
  } else if (isObject(obj)) {
    let accumulated = {} as any;
    const keys = okeys(obj);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (!isObject(obj[k]) && !isArray(obj[k])) {
        accumulated[pathJoin(prefix, k)] = obj[k];
      } else if (isObject(obj[k])) {
        const flat = oflatten(
          obj[k],
          onlyUseLeafNames,
          onlyUseLeafNames ? "" : pathJoin(prefix, k)
        );
        flat; //=
        accumulated = omerge(accumulated, flat);
      } else if (isArray(obj[k])) {
        const flat = oflatten(
          obj[k],
          onlyUseLeafNames,
          onlyUseLeafNames ? "" : pathJoin(prefix, k)
        );
        flat; //=
        accumulated = omerge(accumulated, flat);
      }
    }
    return accumulated;
  } else if (isArray(obj)) {
    let accumulated = {} as any;
    for (let i = 0; i < obj.length; i++) {
      const flat = oflatten(
        { [`[${i}]`]: obj[i] },
        onlyUseLeafNames,
        onlyUseLeafNames ? "" : prefix
      );
      flat; //=
      accumulated = omerge(accumulated, flat);
    }
    return accumulated;
  }
}

// oflatten({ a: 1, b: { c: 2, d: 3 } }, true); //=
// oflatten({ a: 1, b: { c: 2, d: 3 } }); //=
// oflatten({ a: [1, 2, 3] }); //=
// oflatten({ a: [{ b: 34, d: 18 }, 2, 3] }); //=
// oflatten([1, 2, 3]); //=
// oflatten([]); //=
// oflatten({}); //=

export function o2l(obj: any, f: (k: any, v: any) => any): any[] {
  return oitems(obj).map(([k, v]) => f(k, v));
}
/*



888      d8b          888             
888      Y8P          888             
888                   888             
888      888 .d8888b  888888 .d8888b  
888      888 88K      888    88K      
888      888 "Y8888b. 888    "Y8888b. 
888      888      X88 Y88b.       X88 
88888888 888  88888P'  "Y888  88888P' 
                                      
                                      
                                      

*/
export function range(start: number, end?: number) {
  return _.range(start, end);
}

// range(10) //=
// // [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ]
// range(0, 5) //=
// // [ 0, 1, 2, 3, 4 ]
// range(10, 15) //=
// // [ 10, 11, 12, 13, 14 ]

export function lcopy(l: any[]): any[] {
  return _.cloneDeep(l);
}
export function lappend(l: any[], e: any): any[] {
  return [...l, e];
}

// lappend([1, 2, 3, 4], 5); //=
// // [1,2,3,4,5]

export function lappendLeft<T>(l: T[], e: T): T[] {
  return [e, ...l];
}
export function lextend(l1: any[], l2: any[]): any[] {
  return [...l1, ...l2];
}

// lextend([1, 2, 3], [4, 5, 6]); //=
// // [1,2,3,4,5,6]

export function extractKeyValuesAsArray(key: string, l: any[]) {
  console.log("l:", l);
  return l.map((a) => a[key]);
}

export function lremoveAt(l: any[], index: number) {
  return l.slice(0, index).concat(l.slice(index + 1, l.length));
}

// const l = [0, 1, 2, 3, 4, 5];
// lremoveAt(l, 2); //=
// // [ 0, 1, 3, 4, 5 ]

export function lInsertAt(l: any[], index: number, o: any) {
  return l.slice(0, index).concat([o]).concat(l.slice(index, l.length));
}

// const l = [0, 1, 2, 4, 5];
// lInsertAt(l, 3, 3); //=

export function lreplace<T>(
  l: T[],
  element: T,
  f: string | ((arg0: T) => any) = identity
): T[] {
  const _f = _stringKeyToFunction(f);
  return l.map((original) =>
    _f(original) === _f(element) ? element : original
  );
}

export function lenumerate(l: any[]): any[] {
  return Array.from(l.entries());
}
// const l = [8, 7, 5, 4, 5, 6];
// lenumerate(l); //=
// // [ [ 0, 8 ], [ 1, 7 ], [ 2, 5 ], [ 3, 4 ], [ 4, 5 ], [ 5, 6 ] ]

export function lall(l: any[], f: (arg0: any) => any): boolean {
  return l.every(f);
}

export function lnone(l: any[], f: (arg0: any) => any): boolean {
  return l.every((e) => !f(e));
}

// lnone([1, 2, 3], (e) => e === 4); //=
// lnone([1, 2, 3], (e) => e === 2); //=

export function lunique(
  /* Takes the first */
  l: any[],
  f: string | ((arg0: any) => any) = identity
): any[] {
  return _.uniqBy(l, _stringKeyToFunction(f));
}
// const l = [1, 1, 2, 2, 4, 5, 4];
// lunique(l); //=
// // [ 1, 2, 4, 5]

// const l = [
//   { a: 1, b: 2 },
//   { a: 1, b: 3 },
//   { a: 2, b: 9 },
//   { a: 2, b: 6 },
// ];
// lunique(l, (e) => e.a); //=
// lunique(l, "a"); //=
// // [ { a: 1, b: 2 }, { a: 2, b: 9 } ]

export function lzip(...arrays: any[]): any[] {
  return _.zip(...arrays);
}
// lzip([1, 1, 1], [2, 2, 2], [4, 5, 6]); //=
// // [ [ 1, 2, 4 ], [ 1, 2, 5 ], [ 1, 2, 6 ] ]

export function ldiffOrderInsensitive<T>(
  list1: T[],
  list2: T[],
  _f1: string | ((item: T) => any) = identity,
  _f2: string | ((item: T) => any) = identity
): [T[], T[]] {
  // Convert lists to maps for easy comparison
  const map1 = new Map<any, T[]>();
  const map2 = new Map<any, T[]>();
  const f1 = _stringKeyToFunction(_f1);
  const f2 = _stringKeyToFunction(_f2);

  list1.forEach((item) => {
    const key = f1(item);
    if (!map1.has(key)) {
      map1.set(key, []);
    }
    map1.get(key)!.push(item);
  });

  list2.forEach((item) => {
    const key = f2(item);
    if (!map2.has(key)) {
      map2.set(key, []);
    }
    map2.get(key)!.push(item);
  });

  // Calculate diff
  const onlyInList1: T[] = [];
  const onlyInList2: T[] = [];

  map1.forEach((items, key) => {
    if (!map2.has(key) || map2.get(key)!.length < items.length) {
      const diffCount = items.length - (map2.get(key)?.length || 0);
      onlyInList1.push(...items.slice(0, diffCount));
    }
  });

  map2.forEach((items, key) => {
    if (!map1.has(key) || map1.get(key)!.length < items.length) {
      const diffCount = items.length - (map1.get(key)?.length || 0);
      onlyInList2.push(...items.slice(0, diffCount));
    }
  });

  return [onlyInList1, onlyInList2];
}

// ldiffOrderInsensitive([1, 2, 1], [2, 1]); //=

export function lexclusive(
  l1: any[],
  l2: any[],
  _f1: string | ((arg0: any) => any) = identity,
  _f2: string | ((arg0: any) => any) = identity
): [any[], any[]] {
  const f1 = _stringKeyToFunction(_f1);
  const f2 = _stringKeyToFunction(_f2);
  const s1 = lunique(l1, f1);
  const s2 = lunique(l2, f2);
  const only1 = s1.filter((e1) => !s2.some((e2) => equals(f1(e1), f2(e2))));
  const only2 = s2.filter((e2) => !s1.some((e1) => equals(f1(e1), f2(e2))));
  return [only1, only2];
}

// const l1 = [1, 2, 3];
// const l2 = [2, 3, 4];
// lexclusive(l1, l2); //=
// // [ [ 1 ], [ 4 ] ]

// const l3 = [
//   { key: "season", type: "int" },
//   { key: "season", type: "string" },
//   { key: "team", type: "string" },
// ];
// const l4 = [
//   { key: "team", type: "string" },
//   { key: "code", type: "int" },
// ];
// lexclusive(
//   l3,
//   l4,
//   (e) => e.key,
//   (e) => e.key
// ); //=
// // [ { key: 'season', type: 'int' } ], [ { key: 'code', type: 'int' } ] ]

// can also use strings instead of functions for comparison, e.g. lexclusive(l3, l4, "key", "key")

export function lintsx(
  l1: any[],
  l2: any[],
  _f1: string | ((arg0: any) => any) = identity,
  _f2: string | ((arg0: any) => any) = identity,
  dropDuplicates = true
): [any[], any[]] {
  const f1 = _stringKeyToFunction(_f1);
  const f2 = _stringKeyToFunction(_f2);
  const s1 = dropDuplicates ? lunique(l1, f1) : l1;
  const s2 = dropDuplicates ? lunique(l2, f2) : l2;
  const intsx1 = s1.filter((e1) => s2.some((e2) => equals(f1(e1), f2(e2))));
  const intsx2 = s2.filter((e2) => s1.some((e1) => equals(f1(e1), f2(e2))));
  return [intsx1, intsx2];
}

// const l1 = [1, 2, 3, 5];
// const l2 = [2, 3, 4];
// lintsx(l1, l2); //=
// // [ [2, 3], [2, 3] ]

// const l3 = [
//   { key: "season", type: "int" },
//   { key: "season", type: "string" },
//   { key: "team", type: "string" },
// ];
// const l4 = [
//   { key: "team", type: "string" },
//   { key: "code", type: "int" },
// ];
// lintsx(
//   l3,
//   l4,
//   (e) => e.key,
//   (e) => e.key
// ); //=
// // [ [ { key: 'team', type: 'string' } ], [ { key: 'team', type: 'string' } ] ]

// const l5 = [1,2,3]
// const l6 = [5,6,7]
// lintsx(l5, l6); //=
// // [ [], [] ]
// pyfalsey(lintsx(l5, l6)) //=
// // false
// pyfalsey(lfirst(lintsx(l5, l6))) //=
// // true

export function lsplit<T>(
  list: T[],
  predicate: (item: T) => boolean
): [T[], T[]] {
  const trueList: T[] = [];
  const falseList: T[] = [];

  for (const item of list) {
    if (predicate(item)) {
      trueList.push(item);
    } else {
      falseList.push(item);
    }
  }

  return [trueList, falseList];
}

// const l = [1, 2, 3, 4, 5];
// lsplit(l, (e) => e % 2 === 0); //=

export function ljoin(
  l1: any[],
  l2: any[],
  f1: string | ((arg0: any) => any),
  f2: string | ((arg0: any) => any),
  merger: (e1: any, e2: any) => any = (e1: any, e2: any) => [e1, e2]
) {
  return _join.nestedLoopInnerJoin(
    l1,
    _stringKeyToFunction(f1),
    l2,
    _stringKeyToFunction(f2),
    merger
  );
}

// ljoin(
//   [
//     { id: 1, val: 6 },
//     { id: 2, val: 7 },
//     { id: 3, val: 5 },
//   ],
//   [
//     { id: 3, val: 2 },
//     { id: 4, val: 5 },
//     { id: 5, val: 8 },
//   ],
//   "id",
//   (e) => e.id
// ); //=
// [ [ { id: 3, val: 5 }, { id: 3, val: 2 } ] ]

export function lmap2o(
  l: any[],
  kf: (arg0: any) => any,
  vf: (arg0: any) => any
): Record<string, unknown> {
  const mapped = l.map((e: any) => [kf(e), vf(e)]);
  return Object.fromEntries(mapped);
}

// const params = [
//   { key: "season", value: 2021 },
//   { key: "player", value: "Pujols" },
//   { key: "bats", value: "right" },
// ];
// lmap2o(
//   params,
//   (e) => e.key,
//   (e) => e.value
// ); //=
// // { season: 2021, player: 'Pujols', bats: 'right' }
export function lmap2oGen<T, K extends string | number | symbol, V>(
  l: T[],
  kf: (arg0: T) => K,
  vf: (arg0: T) => V
): Record<K, V> {
  const mapped = l.map((e: T) => [kf(e), vf(e)]);
  return Object.fromEntries(mapped) as Record<K, V>;
}

// lmap2oGen(
//   [1, 2, 3],
//   (e) => e,
//   (e) => e
// ); //=

export function lot2o(l: (string | unknown)[][]): Record<string, unknown> {
  return Object.fromEntries(l);
}

// // "List Of Tuples 2 Object"
// const lot = [
//   ["credsId", 123],
//   ["name", "cool_lego"],
// ];
// lot2o(lot); //=
// // {  credsId: 123, name: 'cool_lego' }

export function lfirst<T, F = null>(
  l: T[],
  fallback: F = (null as unknown) as F
): T | F {
  return l.length > 0 ? l[0] : fallback;
}
// const l = [1,2,3,4,5]
// lfirst(l) //=
// // 1

export function lrest(l: any[]): any[] {
  return l.length > 0 ? l.slice(1) : [];
}

// const l = [1,2,3,4,5]
// lrest(l) //=
// lrest([1]) //=
// lrest([]) //=

export function llast(l: any[], fallback: any = null): any | null {
  return l.length > 0 ? l[l.length - 1] : fallback;
}

// const l = [1,2,3,4,5]
// llast(l) //=
// // 5
// llast([]) //=

export function lfindAllIndices(l: any[], f: (arg0: any) => any): any[] {
  return lenumerate(l)
    .filter((e) => f(e[1]))
    .map((e) => e[0]);
}
// const l = [1, 3, 4, 1, 5, 6, 1];
// lfindAllIndices(l, (e) => e == 1); //=
// // [ 0, 3, 6 ]

// export function lfind(
//   l: any[],
//   f: (arg0: any) => any,
//   fallback = undefined as any,
//   makeCopy = false
// ): any {
//   return (makeCopy ? ocopy(l.find(f)) : l.find(f)) || fallback;
// }

export function lfind<T, F = undefined>(
  l: T[],
  f: (item: T) => boolean,
  fallback: F = (undefined as unknown) as F,
  makeCopy = false
): T | F {
  const result = l.find(f);
  return result ? (makeCopy ? ocopy(result) : result) : fallback;
}
// const l = [1, 2, 3, 4];
// lfind(l, (e) => e == 3); //=
// // 3
// lfind(l, (e) => e == 10); //=
// // undefined
// lfind(l, (e) => e == 10, 12); //=
// // 12

export function lfindIndex(l: any[], f: (arg0: any) => any): number {
  return l.findIndex(f);
}

// const l = [10, 15, 12, 21];
// lfindIndex(l, (e) => e == 12); //=
// // 2
// lfindIndex(l, (e) => e == 523); //=
// // -1

type KeyType = string | ((arg0: any) => any);

export function lsort(
  l: any[],
  keys: KeyType[] | string | ((arg0: any) => any) = identity,
  { caseInsensitive = false, reverse = false, nullsLast = false } = {}
): any[] {
  return l.sort((a, b) => {
    const keyArray: KeyType[] = isArray(keys)
      ? (keys as KeyType[])
      : ([keys] as KeyType[]);
    for (const key of keyArray) {
      let x = _stringKeyToFunction(key)(a);
      let y = _stringKeyToFunction(key)(b);

      if (nullsLast) {
        if (x === null && y === null) return 0;
        if (x === null) return reverse ? -1 : 1;
        if (y === null) return reverse ? 1 : -1;
      }

      if (caseInsensitive && isString(x) && isString(y)) {
        x = x.toLowerCase();
        y = y.toLowerCase();
      }
      if (x < y) return reverse ? 1 : -1;
      if (x > y) return reverse ? -1 : 1;
    }
    return 0;
  });
}

// const l = [{ a: 1 }, { a: 4 }, { a: -2 }, { a: 0 }];
// lsort(l, "a"); //=
// // [ { a: -2 }, { a: 0 }, { a: 1 }, { a: 4 } ]
// lsort(l, (e) => e.a); //=
// lsort([5, 3, 1, 6, 7]); //=
// const l2 = [
//   { a: 1, b: -5 },
//   { a: 0, b: 4 },
//   { a: -2, b: 2 },
//   { a: 0, b: 0 },
// ];
// lsort(l2, ["a", "b"]); //=
// lsort(l2, ["a", "b"], { reverse: true }); //=
// lsort(
//   [
//     { tz: "A/Los_Angeles", offsetNum: -6 },
//     { tz: "A/Chicago", offsetNum: -5 },
//     { tz: "A/Anchorage", offsetNum: -6 },
//   ],
//   ["offsetNum", "tz"]
// ); //=

export function lzipBy(
  l1: any[],
  l2: any[],
  f = (arg0: any, arg1: any) => equals(arg0, arg1),
  { keepLeft = false, keepRight = false } = {}
): [any, any][] {
  // f: (arg0: unknown, arg1: unknown) => unknown
  const returnList = [] as [any, any][];
  for (const e1 of l1) {
    const matchIdx = lfindIndex(l2, (s) => f(e1, s));
    if (matchIdx !== -1) {
      returnList.push([e1, l2[matchIdx]]);
      l2 = lremoveAt(l2, matchIdx);
    } else if (keepLeft) {
      returnList.push([e1, null]);
    }
  }
  if (keepRight) {
    for (const e2 of l2) {
      returnList.push([null, e2]);
    }
  }
  return returnList;
}

// const l1 = [1, 2, 3, 4, 5];
// const l2 = [5, 4, 3, 2, 1];
// lzipBy(l1, l2); //=
// // [ [ 1, 1 ], [ 2, 2 ], [ 3, 3 ], [ 4, 4 ], [ 5, 5 ] ]

// const l3 = [1, 1, 1, 4, 5];
// const l4 = [5, 4, 3, 2, 1];
// // only takes 1-1 matches, doesn't duplicate
// lzipBy(l3, l4); //=
// // [ [ 1, 1 ], [ 4, 4 ], [ 5, 5 ] ]

// // keepLeft == true fill blanks with null
// lzipBy(l3, l4, (e1, e2) => equals(e1, e2), { keepLeft: true }); //=
// // [ [ 1, 1 ], [ 1, null ], [ 1, null ], [ 4, 4 ], [ 5, 5 ] ]
// lzipBy(l3, l4, (e1, e2) => equals(e1, e2), { keepLeft: true, keepRight: true }); //=
// // [ [ 1, 1 ], [ 1, null ], [ 1, null ], [ 4, 4 ], [ 5, 5 ], [null, 3], [null, 2] ]

export function lgroupBy(
  l: any[],
  f: (arg0: any) => any | string
): _.Dictionary<any[]> {
  return _.groupBy(l, f);
}

// lgroupBy([1.1, 1.2, 1.3, 5, 0.8], Math.floor); //=
// // { '0': [ 0.8 ], '1': [ 1.1, 1.2, 1.3 ], '5': [ 5 ] }
// lgroupBy(
//   [
//     ["123", "k", "v"],
//     ["123", "k2", "v2"],
//     ["456", "k3", "v3"],
//   ],
//   ([i, k, v]) => i
// ); //=

// function that takes a list size, n, and a chunk size, c, and returns a list of start and end indices for chunks of size c
function lchunkIndices(n: number, c: number): any[] {
  const returnList = [];
  for (let i = 0; i < n; i += c) {
    returnList.push([i, Math.min(i + c, n)]);
  }
  return returnList;
}

// lchunkIndices(10, 3); //=

export function lchunkByPartitions(n: number, p: number) {
  return lchunkIndices(n, Math.ceil(n / p));
}

export function lFilterByBooleanStringExpr(
  l: any[],
  searchExpr: string,
  key: string | ((arg0: any) => any) = (e) => e
): any[] {
  const expr = tokensToBooleanExpr(sToSearchTokens(searchExpr));
  return l.filter((e) =>
    sIncludesUsingExpression(_stringKeyToFunction(key)(e), expr)
  );
}

// lFilterByBooleanStringExpr(["abc", "def", "ghi"], "a"); //=
// lFilterByBooleanStringExpr(["abc", "def", "ghi"], "a OR d"); //=
// lFilterByBooleanStringExpr(["abc", "def", "ghi"], "a AND d"); //=
// lFilterByBooleanStringExpr(["abc", "def", "ghi"], "a AND c"); //=
// lFilterByBooleanStringExpr(["abc", "def", "ghi"], "(a AND c) OR (g AND i)"); //=
// lFilterByBooleanStringExpr(["ban_user", "unban_user"], "ban and un"); //=

export function lapplyFilters(list: any[], filters: any[]): any[] {
  return list.filter((e: any) => {
    for (const filter of filters) {
      if (!filter(e)) {
        return false;
      }
    }
    return true;
  });
}
export function lcontains<T>(
  list: T[],
  item: T,
  { looseEquality = false } = {}
): boolean {
  return list.some((e) => {
    if (looseEquality) {
      return looseEquals(e, item);
    } else {
      return equals(e, item);
    }
  });
}
/*
 .d8888b.  888            d8b                            
d88P  Y88b 888            Y8P                            
Y88b.      888                                           
 "Y888b.   888888 888d888 888 88888b.   .d88b.  .d8888b  
    "Y88b. 888    888P"   888 888 "88b d88P"88b 88K      
      "888 888    888     888 888  888 888  888 "Y8888b. 
Y88b  d88P Y88b.  888     888 888  888 Y88b 888      X88 
 "Y8888P"   "Y888 888     888 888  888  "Y88888  88888P' 
                                            888          
                                       Y8b d88P          
                                        "Y88P"           
*/
export function strim(
  s: string,
  remove: string,
  { right = true } = {}
): string {
  if (!right) {
    const fullyTrimmed = _.trim(s, remove);
    const idx = s.indexOf(fullyTrimmed);
    return s.slice(idx);
  } else {
    return _.trim(s, remove);
  }
}

// const s = "%%abc%%";
// strim(s, "%"); //=
// s; //=
// strim("%%abc%%", "%"); //=
// strim("%%abc%%", "%", { right: false }); //=
// strim("%%abc", "%", { right: false }); //=
// strim("abc%%", "%", { right: false }); //=
// strim("!abc%%", "%!"); //=
// strim("!abc%%", "!%"); //=

export function sReplaceFromObj(
  s: string,
  o: Record<string, string>
): string | any {
  let returnStr = s;
  for (const [k, v] of oitems(o)) {
    if (isString(v)) {
      returnStr = returnStr.replaceAll(k, v as string);
    } else {
      if (k.toString() === s) {
        return v;
      }
    }
  }
  return returnStr;
}

// sReplaceFromObj("abc", { a: "b" }); //=
// sReplaceFromObj("abc", { a: "b", b: "c" }); //=
// sReplaceFromObj("abc", { a: "b", b: "c", c: "d" }); //=
// sReplaceFromObj("abc", {}); //=
// sReplaceFromObj("abc", { d: "e" }); //=
// sReplaceFromObj("abc", { abc: "hi" }); //=
// sReplaceFromObj("abc", { abc: 4, abc: 3 }); //=
// sReplaceFromObj("abc", { abc: 2 }); //=
// sReplaceFromObj("abc", { b: "5", abc: 2, a: 1 }); //=

export function templateMatch(s: string, pattern: RegExp): string[] | null {
  const patternStr = pattern.source;
  const varsReplaced = patternStr.replaceAll(/{[a-zA-Z0-9_]+}/g, "(.*)");
  const match = s.match(RegExp("^" + varsReplaced + "$"));
  if (match == null) {
    return match;
  }
  return match.filter((e, i) => i > 0);
}

export function sIsJSON(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function reverpolate(
  s: string,
  ...patterns: RegExp[]
): Record<string, unknown> {
  const getVars = (pattern: RegExp) => {
    const matches = pattern.source.match(/{[a-zA-Z0-9_]+}|\(.*\)/g);
    if (matches == null) {
      return [];
    }
    const vars = [];
    for (const [i, m] of lenumerate(matches)) {
      if (m.match(/^{.+}$/)) {
        vars.push(strim(m, "{}"));
      } else {
        vars.push(`unnamed_${i}`);
      }
    }
    return vars;
  };
  const distinctVars = (_patterns: RegExp[]): string[] => {
    const allVars = _patterns.flatMap((e) => getVars(e));
    return lunique(allVars);
  };
  for (const pat of patterns) {
    const match = templateMatch(s, pat);
    if (match != null) {
      const matchObject = lmap2o(
        lzip(getVars(pat), match),
        (e) => e[0],
        (e) => e[1]
      );
      const args: Record<string, string | unknown> = {};
      for (const v of distinctVars(patterns)) {
        args[v] = oget(matchObject, v, null);
      }
      return args;
    }
  }
  return lmap2o(
    distinctVars(patterns),
    (e) => e,
    (e) => null
  );
}

// const s1 = "2022-10-05";
// reverpolate(s1, /{year}-{month}-{day}/); //=
// // { day: '05', month: '10', year: '2022' }
// const s2 = "angus@gmail.com";
// reverpolate(s2, /{username}@{mailserver}\.{domain}/); //=
// // { domain: 'com', mailserver: 'gmail', username: 'angus'

export function sToHtml(s: string, _class = ""): string {
  return strim(s, "\n")
    .split("\n")
    .map((e) => `<p class="${_class}">${e}</p>`)
    .join(""); //=
}
// const s =
//   'Traceback (most recent call last):\n  File "/Users/angusmitchell/Workspace/btf/flank-api/app/not_our/service.py", line 125, in fetch_aws_lambdas\n    raise ValueError\nValueError\n';
// sToHtml(s); //=
// // '<p>Traceback (most recent call last):</p><p>  File "/Users/angusmitchell/Workspace/btf/flank-api/app/not_our/service.py", line 125, in fetch_aws_lambdas</p><p>    raise ValueError</p><p>ValueError</p>'

export function snakeToCamel(s: string) {
  return s.replace(/([-_][a-z])/gi, ($1: any) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
}

// snakeToCamel("hello"); //=
// snakeToCamel("hello_world"); //=
// snakeToCamel("hello_world_whoop"); //=
// snakeToCamel("helloWORLDwhoop"); //=
// snakeToCamel("hello1"); //=
// snakeToCamel("hello_1_world"); //=
// snakeToCamel("hello_w0rld"); //=

export function camelToSnake(s: string) {
  return s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

// camelToSnake("helloWorldWhatsUp"); //=
// camelToSnake("hello1"); //=
export function camelToTitle(textString: string) {
  const spliceChars = (
    textStr: string,
    idx: number,
    rem: number,
    str: string
  ): string => {
    return textStr.slice(0, idx) + str + textStr.slice(idx + Math.abs(rem));
  };
  let newString = "";
  let spaceCount = 0;
  for (let letterIndex = 0; letterIndex < textString.length; letterIndex++) {
    if (
      textString.charAt(letterIndex) <= "Z" &&
      textString.charAt(letterIndex) >= "A"
    ) {
      if (newString.length > 0) {
        newString = spliceChars(newString, letterIndex + spaceCount, 0, " ");
        spaceCount++;
        newString += textString.charAt(letterIndex);
      }
    } else if (
      newString.length === 0 &&
      textString.charAt(letterIndex) <= "z" &&
      textString.charAt(letterIndex) >= "a"
    ) {
      newString += textString.charAt(letterIndex).toUpperCase();
    } else {
      newString += textString.charAt(letterIndex);
    }
  }
  return newString;
}

export function camelToSpace(textString: string) {
  const spliceChars = (
    textStr: string,
    idx: number,
    rem: number,
    str: string
  ): string => {
    return textStr.slice(0, idx) + str + textStr.slice(idx + Math.abs(rem));
  };
  let newString = "";
  let spaceCount = 0;
  for (let letterIndex = 0; letterIndex < textString.length; letterIndex++) {
    if (
      textString.charAt(letterIndex) <= "Z" &&
      textString.charAt(letterIndex) >= "A"
    ) {
      if (newString.length > 0) {
        newString = spliceChars(newString, letterIndex + spaceCount, 0, " ");
        spaceCount++;
        newString += textString.charAt(letterIndex);
      }
    } else {
      newString += textString.charAt(letterIndex);
    }
  }
  return newString;
}

// camelToSpace("helloWorldWhatsUp"); //=

export function spaceToTitle(textString: string) {
  const spliceChars = (
    textStr: string,
    idx: number,
    rem: number,
    str: string
  ): string => {
    return textStr.slice(0, idx) + str + textStr.slice(idx + Math.abs(rem));
  };
  let newString = "";
  let spaceCount = 0;
  for (let letterIndex = 0; letterIndex < textString.length; letterIndex++) {
    if (textString.charAt(letterIndex) === " ") {
      if (newString.length > 0) {
        newString = spliceChars(newString, letterIndex + spaceCount, 0, " ");
        spaceCount++;
        newString += textString.charAt(letterIndex + 1).toUpperCase();
        letterIndex++;
      }
    } else if (
      newString.length === 0 &&
      textString.charAt(letterIndex) <= "z" &&
      textString.charAt(letterIndex) >= "a"
    ) {
      newString += textString.charAt(letterIndex).toUpperCase();
    } else {
      newString += textString.charAt(letterIndex);
    }
  }
  return newString;
}

// spaceToTitle("hello world whats up"); //=

export function sToMarkdown(s: string): string {
  return marked.parse(s);
}

// sToMarkdown("hi\nthere"); //=
// sToMarkdown("hi\n\nthere"); //=
// sToMarkdown("hi\n\n\n\nthere"); //=
// sToMarkdown(
//   "\"## Welcome to Flank! <base target='_blank'> \\n #### At the heart of Flank is running kits. Each kit is comprised of some outside code mixed with some Flank special sauce to give you the best experience \\n #### Check out some of Flank's other features by exploring the below kits \\n 1. [Connecting two legos] \\n 2. [Use Flank in Spreadsheets] \\n 3. [Generate a ChatGPT response] \\n \\n [Connecting two legos]: http://localhost:8080/newtestorg-2/builder?id=328 \\n [Use Flank in Spreadsheets]: http://localhost:8080/newtestorg-2/builder?id=329 \\n [Generate a ChatGPT response]: http://localhost:8080/newtestorg-2/builder?id=330\""
//     .replace(/^"|"$/g, "")
//     .replace(/\\n/g, "\n")
// ); //=
// sToMarkdown("# hello\nhello"); //=
// sToMarkdown(
//   "There was an error with sync ID 441 on credential ID 187\n\nBad request\nTalk to the admin of this Function App -- it could be that the request does not have the right credentials or that the request itself was formatted incorrectly somehow\nNoneType: None\n"
// ); //=
// sToMarkdown("hi\n\n<br>"); //=
// sToMarkdown("- hi\n  - hi"); //=

//ID and NAME tokens must begin with a letter ([A-Za-z]) and may be followed by any number of letters, digits ([0-9]), hyphens ("-"), underscores ("_"), colons (":"), and periods (".").
export function htmlSafe(s: string | number) {
  const str = s.toString();
  return str
    .replace(/^[^A-Za-z]/, "z")
    .replace(/[^A-Za-z0-9\-_:]$/, "z")
    .replaceAll(/[^A-Za-z0-9\-_:]/g, "-");
}

// htmlSafe("@what's up?"); //=

export function letterMatch(str1: string, str2: string): boolean {
  const normalizedStr1 = str1.toLowerCase().replace(/_/g, "");
  const normalizedStr2 = str2.toLowerCase().replace(/_/g, "");
  return normalizedStr1 === normalizedStr2;
}

// letterMatch("userId", "user_id"); //=
// letterMatch("USERID", "user_id"); //=
// letterMatch("USerId", "user_id"); //=

export function sToSearchTokens(searchString: string): string[] {
  const keywords = ["AND", "OR", "(", ")", "NOT"];

  // Add spaces around parentheses to ensure they get treated as separate tokens
  const spacedSearchString = searchString
    .replace(/\(/g, " ( ")
    .replace(/\)/g, " ) ");

  // Split the string into potential tokens
  let potentialTokens = spacedSearchString.split(/\s+/);

  // Filter out empty tokens and convert search terms to lowercase
  potentialTokens = potentialTokens.filter((token) => token !== "");

  // Finalize the tokens: keep keywords as is, convert other terms to lowercase
  const tokens = potentialTokens.map((token) =>
    keywords.includes(token.toUpperCase())
      ? token.toUpperCase()
      : token.toLowerCase()
  );

  return tokens;
}

// sToSearchTokens("hello world"); //=
// sToSearchTokens("hello AND world"); //=
// sToSearchTokens("(hello or wazzup) AND world"); //=

type Operand = string;
type Operator = "AND" | "OR" | "NOT";
type Parenthesis = "(" | ")";
type Token = Operand | Operator | Parenthesis;
interface BooleanExpression {
  AND?: (string | BooleanExpression)[];
  OR?: (string | BooleanExpression)[];
  NOT?: string | BooleanExpression;
}

type ShorthandExpression = string | BooleanExpression; // if it's just a string, it's equivalent to { AND: [string] }

export function tokensToBooleanExpr(tokens: Token[]): ShorthandExpression {
  if (tokens.length === 0) {
    return "";
  }
  const stack: Token[] = [];
  const output: ShorthandExpression[] = [];

  for (const token of tokens) {
    if (isOperand(token)) {
      output.push(token);
    } else if (isOperator(token) || token === "(") {
      stack.push(token);
    } else if (token === ")") {
      while (stack.length > 0 && stack[stack.length - 1] !== "(") {
        output.push(popAndCreateExpression(stack, output));
      }
      stack.pop(); // Pop the "("
    }
  }

  while (stack.length > 0) {
    output.push(popAndCreateExpression(stack, output));
  }

  if (output.length > 1) {
    return { AND: output };
  } else {
    return output[0];
  }
}

function isOperand(token: Token): token is Operand {
  return !["AND", "OR", "NOT", "(", ")"].includes(token);
}

function isOperator(token: Token): token is Operator {
  return ["AND", "OR", "NOT"].includes(token);
}

function popAndCreateExpression(
  stack: Token[],
  output: ShorthandExpression[]
): ShorthandExpression {
  const operator = stack.pop() as Operator;
  if (operator === "NOT") {
    return { [operator]: output.pop() } as ShorthandExpression;
  } else {
    const right = output.pop();
    const left = output.pop();
    return { [operator]: [left, right].filter((x) => x !== undefined) };
  }
}

// tokensToBooleanExpr([]); //=
// tokensToBooleanExpr(["hello"]); //=
// tokensToBooleanExpr(["hello", "there"]); //=
// tokensToBooleanExpr(["hello", "there", "man"]); //=
// tokensToBooleanExpr(["(", "hello", "there", ")"]); //=
// tokensToBooleanExpr(["(", "(", "hello", "there", ")", ")"]); //=
// tokensToBooleanExpr(["(", "(", "(", "hello", "there", ")", ")", ")"]); //=
// tokensToBooleanExpr(["(", "hello", "OR", "there", ")"]); //=
// tokensToBooleanExpr([
//   "(",
//   "hello",
//   "OR",
//   "there",
//   ")",
//   "AND",
//   "(",
//   "this",
//   "OR",
//   "that",
//   ")",
// ]); //=
// tokensToBooleanExpr(["NOT", "hello"]); //=
// tokensToBooleanExpr(["this", "AND", "NOT", "that"]); //=

export function sIncludesUsingExpression(
  listedString: string,
  expr: ShorthandExpression
): boolean {
  if (typeof expr === "string") {
    // If expr is a string, return whether it is contained in the listedString.
    return listedString.toLowerCase().includes(expr);
  }

  for (const op in expr) {
    const values = expr[op as Operator] as
      | ShorthandExpression[]
      | ShorthandExpression;
    switch (op) {
      case "AND":
        // If op is 'AND', return whether all values evaluate to true.
        for (const value of values as ShorthandExpression[]) {
          if (!sIncludesUsingExpression(listedString, value)) {
            return false;
          }
        }
        return true;
      case "OR":
        // If op is 'OR', return whether any value evaluates to true.
        for (const value of values as ShorthandExpression[]) {
          if (sIncludesUsingExpression(listedString, value)) {
            return true;
          }
        }
        return false;
      case "NOT":
        // If op is 'NOT', return whether the value evaluates to false.
        return !sIncludesUsingExpression(
          listedString,
          values as ShorthandExpression
        );
    }
  }

  // In case the expr is an empty object.
  return false;
}

// sIncludesUsingExpression("hello", { AND: ["hello"] }); //=
// sIncludesUsingExpression("andhellothere", { AND: ["hello"] }); //=
// sIncludesUsingExpression("hel", { AND: ["hello"] }); //=
// sIncludesUsingExpression("hello there", { AND: ["hello", "there"] }); //=
// sIncludesUsingExpression("hello      there", { AND: ["hello", "there"] }); //=
// sIncludesUsingExpression("whyhellos overthere", { AND: ["hello", "there"] }); //=
// sIncludesUsingExpression("hellothere", { AND: ["hello", "there"] }); //=
// sIncludesUsingExpression("therehello", { AND: ["hello", "there"] }); //=
// sIncludesUsingExpression("hello", { AND: ["hello", "there"] }); //=
// sIncludesUsingExpression("there", { AND: ["hello", "there"] }); //=
// sIncludesUsingExpression("hello there this that", {
//   AND: [{ OR: ["hello", "there"] }, { OR: ["this", "that"] }],
// }); //=
// sIncludesUsingExpression("hello that", {
//   AND: [{ OR: ["hello", "there"] }, { OR: ["this", "that"] }],
// }); //=
// sIncludesUsingExpression("this there", {
//   AND: [{ OR: ["hello", "there"] }, { OR: ["this", "that"] }],
// }); //=
// sIncludesUsingExpression("this that", {
//   AND: [{ OR: ["hello", "there"] }, { OR: ["this", "that"] }],
// }); //=
// sIncludesUsingExpression("hello", { NOT: "hello" }); //=
// sIncludesUsingExpression("ohhellothere", { NOT: "hello" }); //=
// sIncludesUsingExpression("ringobingo", { NOT: "hello" }); //=
// sIncludesUsingExpression("this weee!", { AND: ["this", { NOT: "that" }] }); //=
// sIncludesUsingExpression("something", { AND: ["this", { NOT: "that" }] }); //=
// sIncludesUsingExpression("this that", { AND: ["this", { NOT: "that" }] }); //=
// sIncludesUsingExpression("this that", { NOT: { NOT: "that" } }); //=
// sIncludesUsingExpression("this that", { NOT: { AND: ["this", "that"] } }); //=
// sIncludesUsingExpression("this that", { NOT: { OR: ["this", "that"] } }); //=
// sIncludesUsingExpression("this that", { NOT: { AND: ["this", "weasel"] } }); //=

// sIncludesUsingExpression(
//   "hello there",
//   tokensToBooleanExpr(sToSearchTokens("hello AND there"))
// ); //=
// sIncludesUsingExpression(
//   "hello there",
//   tokensToBooleanExpr(sToSearchTokens("hello and there"))
// ); //=
// sIncludesUsingExpression(
//   "hello there",
//   tokensToBooleanExpr(sToSearchTokens("hello there"))
// ); //=
// sIncludesUsingExpression(
//   "hello",
//   tokensToBooleanExpr(sToSearchTokens("hello there"))
// ); //=
// sIncludesUsingExpression(
//   "ebayConsign",
//   tokensToBooleanExpr(sToSearchTokens("(ebay AND consign) AND NOT sp"))
// ); //=
// sIncludesUsingExpression(
//   "sp_ebayConsign",
//   tokensToBooleanExpr(sToSearchTokens("(ebay AND consign) AND NOT sp"))
// ); //=
export function isEmail(inputString: string): boolean {
  const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return emailRegex.test(inputString);
}

// isEmail("angus@flank.cloud"); //=
// isEmail("angus@flank"); //=
// isEmail("angus@flank.c"); //=
// isEmail("angus"); //=
// isEmail("clay.s.wolcott@gmail.com"); //=
// isEmail("logan@cypress-software-solutions.com"); //=

export function sjoinAnd(l: string[], sep = ", ", lastSep = " and "): string {
  if (l.length <= 2) {
    return l.join(lastSep);
  } else {
    return [l.slice(0, -1).join(sep), l[l.length - 1]].join(lastSep);
  }
}

// sjoinAnd(["a", "b", "c"]); //=
export function quotedSplit(s: string, sep = ",", quoteChar = '"'): string[] {
  const returnList = [];
  let current = "";
  let inQuote = false;
  for (const c of s) {
    if (c === quoteChar) {
      inQuote = !inQuote;
    } else if (sep.includes(c) && !inQuote) {
      returnList.push(current);
      current = "";
    } else {
      current += c;
    }
  }
  returnList.push(current);
  return returnList;
}

// quotedSplit('a, "b, c", d', ','); //=

export function firstLineOfCSV(s: string): string {
  return s.split("\n")[0];
}

// firstLineOfCSV("a,b,c\n1,2,3\n4,5,6"); //=
// firstLineOfCSV(""); //=
// quotedSplit(firstLineOfCSV('a,"b,d",c\n1,2,3\n4,5,6'), ","); //=
export function plural(length: number, thing: string) {
  if (length == 1) {
    return `${length} ${thing}`;
  } else {
    return `${length} ${thing}s`;
  }
}

export function splitByCamelOrSnake(str: string): string[] {
  const split = str.split(/_|(?=[A-Z])/);
  // loop through results and remove empty strings
  for (let i = 0; i < split.length; i++) {
    if (split[i] === "") {
      split.splice(i, 1);
      i--;
    }
  }
  // loop through results and concat adjacent capital letters
  for (let i = 0; i < split.length - 1; i++) {
    if (split[i].match(/^[A-Z]+$/) && split[i + 1].match(/^[A-Z]+$/)) {
      split[i] += split[i + 1];
      split.splice(i + 1, 1);
      i--;
    }
  }
  return split;
}

// splitByCamelOrSnake("helloWorldWhatsUp"); //=
// splitByCamelOrSnake("hello_world_whats_up"); //=
// splitByCamelOrSnake("_hello_world_whats_up_"); //=
// splitByCamelOrSnake("hello_world_whats_up__"); //=
// splitByCamelOrSnake("employeeIDD"); //=

export function removeSpecialCharacters(
  str: string,
  { left = true, middle = true, right = true } = {}
): string {
  if (left && right && middle) {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  } else if (!middle) {
    // if middle is false, only remove special characters at beginning and end
    return str.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, "");
  } else {
    throw new Error("removeSpecialCharacters called with invalid options");
  }
}
// removeSpecialCharacters("_hello_world_whats_up__", { middle: false }); //=
// removeSpecialCharacters("_hello_world_whats_up__"); //=

// splitByCamelOrSnake("@user_id").map(removeSpecialCharacters); //=

export function findLongestString(arr: string[]): string {
  let longest = "";
  for (const str of arr) {
    if (str.length > longest.length) {
      longest = str;
    }
  }
  return longest;
}
// findLongestString(splitByCamelOrSnake("@user_id").map(removeSpecialCharacters)); //=
export function sToNumber(s: string, fallback = NaN): number {
  if (!isSomethingNumeric(s)) {
    return fallback;
  }
  return parseFloat(s);
}
// sToNumber("123.45"); //=
// sToNumber("123"); //=
// sToNumber("123.456789"); //=
// sToNumber("123.456789abc"); //=
// sToNumber("abc"); //=
export function sFirstWord(s: string): string {
  return s.split(" ")[0];
}
// sFirstWord("hello world"); //=
// sFirstWord("hello"); //=
// sFirstWord(""); //=

export function sPossessive(s: string): string {
  return `${s}'s`;
}
// sPossessive("Angus"); //=
// sPossessive("Clay"); //=
/*

888b    888                        888                                
8888b   888                        888                                
88888b  888                        888                                
888Y88b 888 888  888 88888b.d88b.  88888b.   .d88b.  888d888 .d8888b  
888 Y88b888 888  888 888 "888 "88b 888 "88b d8P  Y8b 888P"   88K      
888  Y88888 888  888 888  888  888 888  888 88888888 888     "Y8888b. 
888   Y8888 Y88b 888 888  888  888 888 d88P Y8b.     888          X88 
888    Y888  "Y88888 888  888  888 88888P"   "Y8888  888      88888P' 
                                                                      
                                                                      
                                                                      
*/
export function ordinal(i: number): string {
  if (!Number.isInteger(i)) {
    return i.toString();
  }
  const digit = i % 10,
    teens = i % 100;
  if (digit == 1 && teens != 11) {
    return i + "st";
  }
  if (digit == 2 && teens != 12) {
    return i + "nd";
  }
  if (digit == 3 && teens != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function fraction2strpct(num: number, digits = 0): string {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(num);
}

// fraction2strpct(1 / 2); //=
// // 50%
// fraction2strpct(0.5); //=
// // 50%
// fraction2strpct(0.4992); //=
// // 50%
// fraction2strpct(0.4992, 2); //=
// // 49.92%

/*

88888888888 d8b               d8b                   
    888     Y8P               Y8P                   
    888                                             
    888     888 88888b.d88b.  888 88888b.   .d88b.  
    888     888 888 "888 "88b 888 888 "88b d88P"88b 
    888     888 888  888  888 888 888  888 888  888 
    888     888 888  888  888 888 888  888 Y88b 888 
    888     888 888  888  888 888 888  888  "Y88888 
                                                888 
                                           Y8b d88P 
                                            "Y88P"  
*/
export function sleep(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

type ThrottleCallback = (data: any) => any;

export class ThrottleQueue {
  private stacks: {
    [uniqueKey: string]: {
      stack: Stack<Promise<any>>;
      currentPromiseQueue: Queue<Promise<any>>;
    };
  } = {};

  async enqueue(
    uniqueKey: string,
    promise: Promise<any>,
    callback: ThrottleCallback
  ): Promise<any> {
    if (!(uniqueKey in this.stacks)) {
      this.stacks[uniqueKey] = {
        stack: new Stack<Promise<any>>(),
        currentPromiseQueue: new Queue<Promise<any>>(),
      };
    }

    if (this.stacks[uniqueKey].currentPromiseQueue.isEmpty()) {
      this.stacks[uniqueKey].currentPromiseQueue.enqueue(promise);
      try {
        const resp = await promise;
        return callback(resp.data);
      } finally {
        this.stacks[uniqueKey].currentPromiseQueue.dequeue();
      }
    } else {
      this.stacks[uniqueKey].stack.push(promise);
      const size0 = this.stacks[uniqueKey].stack.size();

      this.stacks[uniqueKey].currentPromiseQueue
        .front()
        ?.then((inProgressResp) => {
          const sizeT = this.stacks[uniqueKey].stack.size();
          if (size0 == sizeT) {
            this.stacks[uniqueKey].currentPromiseQueue.enqueue(promise);
            return promise
              .then((resp) => {
                return callback(resp.data);
              })
              .finally(() => {
                this.stacks[uniqueKey].currentPromiseQueue.dequeue();
              });
          } else {
            return callback(inProgressResp.data);
          }
        })
        .catch((e) => {
          this.stacks[uniqueKey].currentPromiseQueue.enqueue(promise);
          return promise
            .then((resp) => {
              return callback(resp.data);
            })
            .finally(() => {
              this.stacks[uniqueKey].currentPromiseQueue.dequeue();
            });
        });
    }
  }
}

/*


8888888b.           888                        d88P      88888888888 d8b                        
888  "Y88b          888                       d88P           888     Y8P                        
888    888          888                      d88P            888                                
888    888  8888b.  888888 .d88b.           d88P             888     888 88888b.d88b.   .d88b.  
888    888     "88b 888   d8P  Y8b         d88P              888     888 888 "888 "88b d8P  Y8b 
888    888 .d888888 888   88888888        d88P               888     888 888  888  888 88888888 
888  .d88P 888  888 Y88b. Y8b.           d88P                888     888 888  888  888 Y8b.     
8888888P"  "Y888888  "Y888 "Y8888       d88P                 888     888 888  888  888  "Y8888  
                                                                                                
                                       
                                       
*/
export function formatTimestamp(ts: string, fallback = ""): string {
  if (ts != null) {
    const isoDate = new Date(ts);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcToZonedTime(isoDate, timeZone);
    const timeAgo = formatTimeAgo(localDate);
    return timeAgo;
  } else {
    return fallback;
  }
}

export function dateToYYYYMMDD(date: Date): string {
  return date.toISOString().split("T")[0];
}

// dateToYYYYMMDD(new Date()); //=
export function dateToYYYYMMDD12HHMMSSAMPM(date: Date): string {
  return (
    date.toISOString().split("T")[0] +
    " " +
    date.toLocaleString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    })
  );
}

// dateToYYYYMMDD12HHMMSSAMPM(new Date()); //=

export function dateToWDMDYY12HHMMAMPMTZLocal(date: Date): string {
  return date.toLocaleString("en-US", {
    weekday: "short",
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
  });
}

// dateToWDMDYY12HHMMAMPMTZLocal(new Date()); //=

export function dateToDMYYHMMSSAPMTZ(date: Date, timezone: string): string {
  // Convert the provided date to the specified timezone
  const zonedDate = utcToZonedTime(date, timezone);

  // Format the date with the desired format including timezone abbreviation
  const formattedDate = format(zonedDate, "M/d/yy h:mm:ss a zzz", {
    timeZone: timezone,
  });

  return formattedDate;
}

// dateToDMYYHMMSSAPMTZ(new Date(), "America/Chicago"); //=

export function parseTimezoneFromDateString(input: string): string | null {
  const timezoneAbbrs = okeys(timezoneAbbrMap());
  const matchingAbbr = timezoneAbbrs.find((abbr) => input.endsWith(abbr));
  if (matchingAbbr) {
    return abbreviationToIANATimeZone(matchingAbbr);
  }
  return null;
}

// parseTimezoneFromDateString("1/1/2021 12:00:00 AM PST"); //=

export function tzOffsetRelativeToBrowserHrs(date: Date, tz: string) {
  // Get the timezone of the browser
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to UTC and then to the target timezone
  const dateInUTC = zonedTimeToUtc(date, browserTimezone);
  const dateInTargetTimezone = utcToZonedTime(date, tz); // replace with your target timezone

  // Calculate the offset in milliseconds
  const offset = dateInTargetTimezone.getTime() - dateInUTC.getTime();

  // Convert offset to hours
  const offsetInHours = offset / 3600000;

  return offsetInHours;
}

// tzOffsetRelativeToBrowserHrs(new Date(), "America/New_York"); //=
// tzOffsetRelativeToBrowserHrs(new Date(2023, 6, 1), "America/New_York"); //=
// tzOffsetRelativeToBrowserHrs(new Date(2023, 12, 1), "America/New_York"); //=
// tzOffsetRelativeToBrowserHrs(new Date(2023, 6, 1), "America/Phoenix"); //=
// tzOffsetRelativeToBrowserHrs(new Date(2023, 12, 1), "America/Phoenix"); //=
// tzOffsetRelativeToBrowserHrs(new Date(), "America/Los_Angeles"); //=
// tzOffsetRelativeToBrowserHrs(new Date(), "Asia/Tokyo"); //=

function tzOffsetRelativeToUTC(timeZone: string, date = new Date()): number {
  const offsetInMinutes = getTimezoneOffset(timeZone, date);
  return offsetInMinutes / 3600000;
}

// tzOffsetRelativeToUTC("America/Chicago"); //=
// tzOffsetRelativeToUTC("Europe/Paris"); //=
// tzOffsetRelativeToUTC("America/Anchorage"); //=
// tzOffsetRelativeToUTC("Etc/UTC"); //=

export function createTzOffsetList(date: Date, common = false): any[] {
  let returnList = [] as any[];
  if (!date) {
    date = new Date();
  }
  for (const tz of common ? tzListCommon() : tzList()) {
    const offset = tzOffsetRelativeToUTC(tz, date);
    returnList.push({
      tz,
      offset: offset >= 0 ? `+${offset}` : offset.toString(),
      offsetNum: offset,
    });
  }
  returnList = lsort(returnList, ["offsetNum", "tz"]);
  return returnList;
}

// createTzOffsetList(); //=

export function formatDurationMMmSSs(
  start: string | null,
  end: string | null
): string {
  if (start == null || end == null) {
    return "";
  }
  const startTs = Date.parse(start);
  const endTs = Date.parse(end);

  if (isNaN(startTs) || isNaN(endTs)) {
    return "";
  }
  if (endTs < startTs) {
    return "";
  }
  const durationInMs = endTs - startTs;
  const hours = Math.floor(durationInMs / 3600000);
  const minutes = Math.floor((durationInMs % 3600000) / 60000);
  const seconds = Math.floor((durationInMs % 60000) / 1000);
  if (hours === 0 && minutes === 0) {
    return `${seconds}s`;
  } else if (hours === 0) {
    return `${minutes}m ${seconds.toString().padStart(2, "0")}s`;
  } else {
    return `${hours}h ${minutes
      .toString()
      .padStart(2, "0")}m ${seconds.toString().padStart(2, "0")}s`;
  }
}
// formatDurationMMmSSs(
//   "2024-01-12T22:12:26.363855+00:00",
//   "2024-01-12T22:11:26.363855+00:00"
// ); //=
// formatDurationMMmSSs("2024-01-12T22:12:26.363855+00:00", null); //=
// formatDurationMMmSSs(
//   "2024-01-12T22:09:22.363855+00:00",
//   "2024-01-12T22:09:26.363855+00:00"
// ); //=
// formatDurationMMmSSs(
//   "2024-01-12T22:09:22.363855+00:00",
//   "2024-01-12T22:09:36.363855+00:00"
// ); //=
// formatDurationMMmSSs(
//   "2024-01-12T22:09:22.363855+00:00",
//   "2024-01-12T22:11:26.363855+00:00"
// ); //=
// formatDurationMMmSSs(
//   "2024-01-12T22:09:22.363855+00:00",
//   "2024-01-12T23:11:26.363855+00:00"
// ); //=
/*

888b     d888          888    888      
8888b   d8888          888    888      
88888b.d88888          888    888      
888Y88888P888  8888b.  888888 88888b.  
888 Y888P 888     "88b 888    888 "88b 
888  Y8P  888 .d888888 888    888  888 
888   "   888 888  888 Y88b.  888  888 
888       888 "Y888888  "Y888 888  888 
                                       
                                       
                                       
*/

export function modulo(n: number, d: number): number {
  return ((n % d) + d) % d;
}
// modulo(5, 3); //=
// 0 % 3;
// // 2
// modulo(2, 10); //=
// // 2
// modulo(101, 10) //=
// // 1

/*

8888888b.          d8b               d8b 888    d8b                                     88888888888                                  
888   Y88b         Y8P               Y8P 888    Y8P                                         888                                      
888    888                               888                                                888                                      
888   d88P 888d888 888 88888b.d88b.  888 888888 888 888  888  .d88b.  .d8888b               888  888  888 88888b.   .d88b.  .d8888b  
8888888P"  888P"   888 888 "888 "88b 888 888    888 888  888 d8P  Y8b 88K                   888  888  888 888 "88b d8P  Y8b 88K      
888        888     888 888  888  888 888 888    888 Y88  88P 88888888 "Y8888b.              888  888  888 888  888 88888888 "Y8888b. 
888        888     888 888  888  888 888 Y88b.  888  Y8bd8P  Y8b.          X88 d8b          888  Y88b 888 888 d88P Y8b.          X88 
888        888     888 888  888  888 888  "Y888 888   Y88P    "Y8888   88888P' 88P          888   "Y88888 88888P"   "Y8888   88888P' 
                                                                               8P                     888 888                        
                                                                               "                 Y8b d88P 888                        
                                                                                                  "Y88P"  888                        
*/
export function isObject(something: any): boolean {
  return (
    typeof something === "object" &&
    !Array.isArray(something) &&
    something !== null
  );
}
export function isObject2<T = object>(something: any): something is T {
  return (
    typeof something === "object" &&
    !Array.isArray(something) &&
    something !== null
  );
}
export function isArray(something: any): boolean {
  return Array.isArray(something);
}
export function isArray2<T = any>(something: any): something is T[] {
  return Array.isArray(something);
}
// isArray2(null) //=
// isArray2(undefined) //=
export function isSomethingNumeric(something: any): boolean {
  if (isNumber(something)) {
    return true;
  } else if (isString(something)) {
    if (!/^[-+]?(\d*\.?\d+|\d+\.?\d*)$/.test(something.trim())) {
      return false;
    }

    return !isNaN(parseFloat(something));
  }
  return false;
}
// isSomethingNumeric(1); //=
// isSomethingNumeric("1"); //=
// isSomethingNumeric("123"); //=
// isSomethingNumeric("1.1"); //=
// isSomethingNumeric("00"); //=
// isSomethingNumeric("00."); //=
// isSomethingNumeric("00.123"); //=

// isSomethingNumeric("one"); //=
// isSomethingNumeric("123abc"); //=
// isSomethingNumeric(null); //=
// isSomethingNumeric(true); //=
// isSomethingNumeric("true"); //=
// isSomethingNumeric({}); //=
// isSomethingNumeric([]); //=

export function isNumber(something: any): boolean {
  return typeof something === "number";
}

// isNumber(1); //=
// isNumber("1"); //=
// isNumber("one"); //=
// isNumber(true); //=
// isNumber({}); //=
// isNumber([]); //=

export function isDate(something: any): boolean {
  return something instanceof Date && !isNaN(something.valueOf());
}

export function equals(value: any, other: any): boolean {
  return _.isEqual(value, other);
}
// equals(1, "1"); //=
export function looseEquals(value: any, other: any): boolean {
  return value == other;
}

export function isBoolean(something: any): boolean {
  return typeof something === "boolean";
}

export function isBoolean2(something: any): something is boolean {
  return typeof something === "boolean";
}
export function isString(something: any): something is string {
  return typeof something === "string";
}

export function tryEval(input: string): any {
  try {
    return eval(input);
  } catch (e) {
    return input;
  }
}

export function enforceType<T>(obj: T): T {
  return obj;
}

// tryEval("1+1"); //=
// tryEval("this is not a valid expression"); //=
// tryEval("[1,2,3]"); //=

export function inEnum<T extends string>(
  value: string,
  enumObj: { [key: string]: T }
): value is T {
  return Object.values(enumObj).includes(value as T);
}

export function enforceEnum<T extends { [key: string]: string }>(
  value: string,
  enumObj: T
): T[keyof T] {
  const enumValues = Object.values(enumObj);
  if (enumValues.includes(value)) {
    return value as T[keyof T];
  }
  throw new Error(`Value ${value} is not in enum ${enumObj}`);
}
export function enforceEnumOrNull<T extends { [key: string]: string }>(
  value: string,
  enumObj: T
): T[keyof T] | null {
  const enumValues = Object.values(enumObj);
  if (enumValues.includes(value)) {
    return value as T[keyof T];
  }
  return null;
}
/*

888b    888          888 888                   8888888888       888                            
8888b   888          888 888                   888              888                            
88888b  888          888 888                   888              888                            
888Y88b 888 888  888 888 888 .d8888b           8888888  8888b.  888 .d8888b   .d88b.  888  888 
888 Y88b888 888  888 888 888 88K               888         "88b 888 88K      d8P  Y8b 888  888 
888  Y88888 888  888 888 888 "Y8888b.          888     .d888888 888 "Y8888b. 88888888 888  888 
888   Y8888 Y88b 888 888 888      X88 d8b      888     888  888 888      X88 Y8b.     Y88b 888 
888    Y888  "Y88888 888 888  88888P' 88P      888     "Y888888 888  88888P'  "Y8888   "Y88888 
                                      8P                                                   888 
                                      "                                               Y8b d88P 
                                                                                       "Y88P"  
*/
export function nullish(thing: any): boolean {
  return typeof thing === "undefined" || thing === null;
}

// nullish(0) //=

export function falsey(
  thing: any
): thing is false | null | undefined | "" | 0 | 0n {
  return (
    typeof thing === "undefined" ||
    thing === "null" ||
    thing === null ||
    thing === false ||
    (typeof thing === "string" && thing == "") ||
    (typeof thing === "number" && (thing == 0 || Number.isNaN(thing))) ||
    (typeof thing == "bigint" && thing == 0n)
  );
}

type EmptyObject = { [K in any]: never };

export function pyfalsey(
  thing: any
): thing is false | null | undefined | "" | 0 | 0n | [] | EmptyObject {
  return (
    falsey(thing) ||
    (Array.isArray(thing) && thing.length == 0) ||
    (typeof thing == "object" && oempty(thing))
  );
}

// // true
// pyfalsey({}); //=
// pyfalsey([]); //=
// pyfalsey(null); //=
// pyfalsey("null"); //=
// pyfalsey(false); //=
// pyfalsey(undefined); //=
// pyfalsey(0); //=
// pyfalsey(NaN); //=
// pyfalsey(""); //=

// // false
// pyfalsey("undefined"); //=
// pyfalsey("weasel"); //=
// pyfalsey(1); //=

type Truthy = Exclude<
  any,
  false | null | undefined | "" | 0 | 0n | [] | { [K in any]: never }
>;

export function pytruthy<T>(thing: T): thing is Truthy & T {
  return !pyfalsey(thing);
}
export function pyCoalesce(...things: any): any | null {
  for (const thing of things) {
    if (!pyfalsey(thing)) {
      return thing;
    }
  }
  return null;
}

/*

8888888888                         888    d8b                            
888                                888    Y8P                            
888                                888                                   
8888888 888  888 88888b.   .d8888b 888888 888  .d88b.  88888b.  .d8888b  
888     888  888 888 "88b d88P"    888    888 d88""88b 888 "88b 88K      
888     888  888 888  888 888      888    888 888  888 888  888 "Y8888b. 
888     Y88b 888 888  888 Y88b.    Y88b.  888 Y88..88P 888  888      X88 
888      "Y88888 888  888  "Y8888P  "Y888 888  "Y88P"  888  888  88888P' 
                                                                         
                                                                         
                                                                         
*/

export function iterapply(x: any, ...funcs: ((arg0: any) => any)[]): any {
  return funcs.reduce((acc, f) => f(acc), x);
}

// iterapply(
//   3,
//   (e) => e + 3,
//   (e) => e + 5
// ); //=
// // 11

// iterapply(5, ...[]) //=
// // 5

function theTimeout(ms: number): Promise<never> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Operation timed out after " + ms + " ms"));
    }, ms);
  });
}
export function timeout<T>(promise: Promise<T>, ms: number): Promise<T> {
  return Promise.race([promise, theTimeout(ms)]);
}
/*

8888888b.           888                   .d8888b.  888                              888                                      
888  "Y88b          888                  d88P  Y88b 888                              888                                      
888    888          888                  Y88b.      888                              888                                      
888    888  8888b.  888888  8888b.        "Y888b.   888888 888d888 888  888  .d8888b 888888 888  888 888d888 .d88b.  .d8888b  
888    888     "88b 888        "88b          "Y88b. 888    888P"   888  888 d88P"    888    888  888 888P"  d8P  Y8b 88K      
888    888 .d888888 888    .d888888            "888 888    888     888  888 888      888    888  888 888    88888888 "Y8888b. 
888  .d88P 888  888 Y88b.  888  888      Y88b  d88P Y88b.  888     Y88b 888 Y88b.    Y88b.  Y88b 888 888    Y8b.          X88 
8888888P"  "Y888888  "Y888 "Y888888       "Y8888P"   "Y888 888      "Y88888  "Y8888P  "Y888  "Y88888 888     "Y8888   88888P' 
                                                                                                                              
                                                                                                                              
                                                                                                                              
*/

export class Stack<T> {
  private stack: T[] = [];

  public push(item: T): void {
    this.stack.push(item);
  }

  public pop(): T | undefined {
    return this.stack.pop();
  }

  public peak(): T | undefined {
    return this.stack[this.stack.length - 1];
  }

  public clear(): void {
    this.stack = [];
  }

  public size(): number {
    return this.stack.length;
  }
}

// example of initialization
// const stack = new Stack<number>();
// stack.push(1);
export class Queue<T> {
  private _queue: T[] = [];

  public enqueue(item: T): void {
    this._queue.push(item);
  }

  public dequeue(): T | undefined {
    return this._queue.shift();
  }

  public isEmpty(): boolean {
    return this._queue.length === 0;
  }

  public front(): T | undefined {
    return this._queue[0];
  }
}

/*

8888888b.           888                        
888  "Y88b          888                        
888    888          888                        
888    888  .d88b.  88888b.  888  888  .d88b.  
888    888 d8P  Y8b 888 "88b 888  888 d88P"88b 
888    888 88888888 888  888 888  888 888  888 
888  .d88P Y8b.     888 d88P Y88b 888 Y88b 888 
8888888P"   "Y8888  88888P"   "Y88888  "Y88888 
                                           888 
                                      Y8b d88P 
                                       "Y88P"  
*/
export function beep(title = "ding"): void {
  const audio = new Audio(`${title}.mp3`);
  audio.play();
}

/*
888     888 8888888b.  888              
888     888 888   Y88b 888              
888     888 888    888 888              
888     888 888   d88P 888     .d8888b  
888     888 8888888P"  888     88K      
888     888 888 T88b   888     "Y8888b. 
Y88b. .d88P 888  T88b  888          X88 
 "Y88888P"  888   T88b 88888888 88888P' 
                                        
                                        
                                        
*/

export function windowToFullPath(window: Window): string {
  return window.location.pathname + window.location.search;
}

// windowToFullPath({
//   location: {
//     protocol: "https:",
//     host: "localhost:8080",
//     pathname: "/path/to/something",
//     search: "?this=that&?that=this",
//   },
// }); //=

export function urlJoin(l: string, r: string): string {
  return strim(l, "/") + "/" + strim(r, "/", { right: false });
}

// urlJoin("http://localhost:8080", "/path"); //=
// urlJoin("http://localhost:8080/", "/path"); //=
// urlJoin("http://localhost:8080", "path"); //=
// urlJoin("http://localhost:8080/", "path"); //=
// urlJoin("http://localhost:8080/", "path/"); //=
// urlJoin("http://localhost:8080/", "path/"); //=

export function fullPathToPath(fullPath: string): string {
  const qIdx = fullPath.indexOf("?");
  if (qIdx < 0) {
    return fullPath;
  } else {
    return fullPath.slice(0, qIdx);
  }
}

// fullPathToPath("/path?this=that&that=this"); //=
// fullPathToPath("/path?this=that&that=this?"); //=
// fullPathToPath("/path"); //=
export function urlToHost(url: string): string {
  try {
    const urlObj = new URL(url);
    return urlObj.host;
  } catch {
    return "";
  }
}

// urlToHost("https://www.google.com/analytics?hi=yes"); //= "www.google.com"
// urlToHost("http://localhost:8080/path"); //= "localhost:8080"
// urlToHost("invalid-url"); //= ""

export function urlToPath(url: string): string {
  try {
    const urlObj = new URL(url);
    return decodeURIComponent(urlObj.pathname).replace(/^\//, "");
  } catch {
    return "";
  }
}

urlToPath("https://www.google.com/analytics?hi=yes"); //=
urlToPath("https://www.google.com/:analytics/{hi}?hi=yes"); //=

/**
 * Extracts path and query parameters from a URL template
 * @param urlTemplate URL template with {param} placeholders
 * @returns Object with pathParams and queryParams arrays
 */
export function extractUrlParams(
  urlTemplate: string
): {
  pathParams: string[];
  queryParams: string[];
} {
  const pathParams: string[] = [];
  const queryParams: string[] = [];

  // Split URL into path and query parts
  const [path, query] = urlTemplate.split("?");

  // Extract path parameters
  const pathParamMatches = path.match(/\{([^}]+)\}/g) || [];
  pathParams.push(...pathParamMatches.map((p) => p.slice(1, -1)));

  // Extract query parameters if query string exists
  if (query) {
    const queryParts = query.split("&");
    queryParts.forEach((part) => {
      const [key] = part.split("=");
      queryParams.push(key);
    });
  }

  return {
    pathParams,
    queryParams,
  };
}

// extractUrlParams("https://api.com/users/{id}?active=true"); //=
// extractUrlParams("https://api.com/orgs/{orgId}/users/{userId}"); //=
// extractUrlParams("https://api.com/search?q=test&page={page}&limit={limit}"); //=
// extractUrlParams("https://api.com/search"); //=

//  .d8888b.
// d88P  Y88b
// 888    888
// 888        888d888 .d88b.  88888b.
// 888        888P"  d88""88b 888 "88b
// 888    888 888    888  888 888  888
// Y88b  d88P 888    Y88..88P 888  888
//  "Y8888P"  888     "Y88P"  888  888

export function nextCronUTC(expression: string, tz: string): Date | null {
  try {
    const interval = parser.parseExpression(expression, {
      currentDate: new Date().toISOString(),
      tz: tz,
    });
    return interval.next().toDate();
  } catch (e) {
    return null;
  }
}

// nextCronUTC("0 17 * * *", "America/New_York"); //=
// nextCronUTC("0 17 * * *", "America/Chicago"); //=

export function cronToNaturalLanguage(expression: string) {
  try {
    return cronstrue.toString(expression);
  } catch (e) {
    return "Error";
  }
}

// cronToNaturalLanguage("0 17 * * *"); //=
// cronToNaturalLanguage("* * *"); //=
