var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-col card-container",class:_vm.bgAndTextCss,style:(_vm.zIndexBump ? 'z-index: 26' : 'z-index: 1')},[_c('LegoCardHeader',{attrs:{"data-flank":"lego-card-header","runOrder":_vm.runOrder,"frontendState":_vm.frontendState,"runResult":_vm.runResult,"runCarousel":_vm.runCarousel,"runrams":_vm.runrams,"prevRunrams":_vm.prevRunrams,"nextRunrams":_vm.nextRunrams,"cssState":_vm.cssState,"transformedPayload":_vm.transformedPayload,"isCopycatKit":_vm.isCopycatKit},on:{"emitShowFullScreenDataModal":_vm.emitShowFullScreenDataModal}}),_c('div',{staticClass:"tw-relative tw-flex-1",attrs:{"data-flank":"lego-card-body"}},[_c('div',{staticClass:"tw-absolute tw-top-0 tw-right-0 tw-left-0 tw-bottom-0"},[(_vm.frontendState === 'running' || _vm.viewLiveLogs)?_c('LegoCardPriusMap',{attrs:{"frontendState":_vm.frontendState,"elapsedRuntime":_vm.elapsedRuntime,"legoName":_vm.legoDoll.legoName,"runningMessageProp":_vm.legoDoll.runningMessage,"loggingMessage":_vm.runResult.loggingMessage}}):_vm._e(),(!_vm._pyfalsey(_vm.error))?_c('ErrorBox',{staticClass:"tw-mx-5 tw-mt-5",attrs:{"error":_vm.error,"contactAdminTitle":"There was an error running the chain","bugReportTitle":"Run error","bugReportBody":JSON.stringify(_vm.runResult, null, '\t'),"legoDoll":_vm.legoDoll,"kitDoll":_vm.kitDoll},on:{"tryAgain":function($event){return _vm.$emit('tryAgain')},"emitKitDollUpdate":function($event){return _vm.$emit('emitKitDollUpdate', $event)}}}):_vm._e(),(!_vm._pyfalsey(_vm.runResult.payload) && !_vm.viewLiveLogs)?_c('LegoCardPayload',{staticClass:"tw-p-8 tw-h-full",attrs:{"frontendState":_vm.frontendState,"runResult":_vm.runResult,"transformedPayload":_vm.transformedPayload,"legoDoll":_vm.legoDoll,"isCmd":_vm.isCmd},on:{"emitActiveMultisetTab":function($event){_vm.activeMultisetTab = $event}}}):(
          _vm._pyfalsey(_vm.runResult.payload) && _vm.runResult.status === 'success'
        )?_c('div',[_c('p',{staticClass:"tw-my-4 tw-mx-6 tw-border-2 tw-bg-gray-100 tw-p-5"},[_vm._v(" Command ran successfully but did not return an output ")])]):_vm._e()],1)]),(
      (_vm._pytruthy(_vm.runResult.payload) ||
        _vm._pytruthy(_vm.runResult.lambdaRequestId) ||
        _vm._pytruthy(_vm.runResult.cloudwatchUrl)) &&
      !_vm.isDemo
    )?_c('div',{staticClass:"tw-p-5 tw-flex tw-flex-row tw-justify-end tw-flex-wrap tw-gap-4",attrs:{"data-flank":"lego-card-footer"}},[(_vm.runResult.fileStatus == 'success')?_c('div',[_c('b-button',{attrs:{"type":'is-primary',"outlined":true,"icon-left":"download"},on:{"click":function($event){$event.stopPropagation();_vm.downloadDisplayedData(
            _vm.runResult.extensions.includes('csv')
              ? 'csv'
              : _vm.runResult.extensions.includes('xlsx')
              ? 'xlsx'
              : _vm.runResult.extensions.split(',')[0],
            _vm.runResult.runId
          )}}},[_vm._v(_vm._s(_vm.runResult.extensions.includes("csv") ? "csv" : _vm.runResult.extensions.includes("xlsx") ? "xlsx" : _vm.runResult.extensions.split(",")[0]))])],1):_vm._e(),_c('div',[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},on:{"active-change":function (change) {
            _vm.zIndexBump = change;
          }},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var active = ref.active;
return [_c('b-button',{staticClass:"tw-text-lg",attrs:{"label":"More Actions","type":"is-primary is-outlined","icon-left":"","icon-right":active ? 'caret-up' : 'caret-down'}})]}}],null,false,110118347)},[(_vm._pytruthy(_vm.runrams))?_c('b-dropdown-item',{on:{"click":function($event){_vm.$emit('emitUseTheseRunrams', {
              runrams: _vm._runramsToStr(_vm.runrams),
              kitInstanceId: null,
            })}}},[_vm._v("Use these params")]):_vm._e(),(_vm.runResult.fileStatus == 'success')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('emitOpenBatchDownloadSidePanel')}}},[_vm._v("Combine and Download")]):_vm._e(),(
            _vm.runResult.payloadDisplayType &&
            _vm.runResult.extensions &&
            _vm.runResult.status == 'success' &&
            (_vm.runResult.payloadDisplayType == 'csv' ||
              _vm.runResult.payloadDisplayType == 'json.table.rowwise' ||
              _vm.runResult.payloadDisplayType == 'json.multiset.rowwise')
          )?_c('b-dropdown-item',{on:{"click":_vm.copyResults}},[_vm._v("Copy Results")]):_vm._e(),(
            _vm.runResult.payloadDisplayType &&
            _vm.runResult.extensions &&
            _vm.runResult.status == 'success' &&
            (_vm.runResult.payloadDisplayType == 'csv' ||
              _vm.runResult.payloadDisplayType == 'json.table.rowwise' ||
              _vm.runResult.payloadDisplayType == 'json.multiset.rowwise')
          )?_c('b-dropdown-item',{on:{"click":_vm.emitOpenEmailResultsSidePanel}},[_vm._v("Email Results")]):_vm._e(),(
            _vm._isLambda(_vm.legoDoll.cloudId) ||
            _vm._isStepFunction(_vm.legoDoll.cloudId) ||
            _vm._isGithub(_vm.legoDoll.cloudId)
          )?_c('b-dropdown-item',{on:{"click":function($event){_vm.viewLiveLogs = !_vm.viewLiveLogs}}},[_vm._v(_vm._s(!_vm.viewLiveLogs ? "View Live Logs" : "View Run Results"))]):_vm._e(),(
            (!_vm.cannotEditKit || _vm.isOgAdmin) &&
            (_vm._isLambda(_vm.legoDoll.cloudId) || _vm._isStepFunction(_vm.legoDoll.cloudId))
          )?[_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{attrs:{"custom":""}},[_c('div',{staticClass:"tw-font-bold tw-text-xs text-gray-600"},[_vm._v(" ENGINEER ZONE ")])]),(
              _vm._isLambda(_vm.legoDoll.cloudId) || _vm._isStepFunction(_vm.legoDoll.cloudId)
            )?_c('b-dropdown-item',{attrs:{"disabled":_vm._pyfalsey(_vm.runResult.lambdaRequestId) &&
              _vm._pyfalsey(_vm.runResult.cloudwatchUrl)},on:{"click":_vm.copyCloudwatchUrl}},[_vm._v("Copy Cloudwatch URL")]):_vm._e()]:_vm._e()],2)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }